import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ApiProvider from '../../contexts/ApiContext';
import useDynamicLang from '../../hooks/useDynamicLang';
import RoutesList, { ScrollToTop } from '../../routes/RoutesList';
import { setupStore } from '../../store/store';
import { timezones } from '../NavbarComponent/consts';
import './styles.scss';

const store = setupStore();

const App: React.FC = () => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	useEffect(() => {
		let savedTimezone = localStorage.getItem('timezone');

		if (!savedTimezone) {
			// Получаем смещение от UTC в часах
			const utcOffset = dayjs().utcOffset() / 60;

			const matchedTimezone = timezones.find(
				(timezone) =>
					timezone.utc === `${utcOffset >= 0 ? '+' : ''}${utcOffset}`
			);

			if (matchedTimezone) {
				savedTimezone = matchedTimezone.utc;
			} else {
				savedTimezone = '+2';
			}

			localStorage.setItem('timezone', savedTimezone);
		}
	}, []);

	const { i18n } = useTranslation();

	useDynamicLang(i18n.language);

	return (
		<Router basename={process.env.PUBLIC_URL}>
			<ApiProvider>
				<Provider store={store}>
					<ToastContainer />
					<ScrollToTop />
					<div
						className={`App ${
							isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
						}`}>
						<RoutesList />
					</div>
				</Provider>
			</ApiProvider>
		</Router>
	);
};

export default App;
