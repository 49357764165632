import React from 'react';

import logo from '../../assets/icons/logo_vigiland_1_white 1.svg';
import './styles.scss';

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='logoLines'>
				<hr />
				<div className='logo'>
					<img src={logo} alt='Logo' className='logoImg' />
					<div className='logoText'>
						<span className='logoName'>Vigiland</span>
						<div className='parserLabel'>parser</div>
					</div>
				</div>
				<hr />
			</div>
			<p>© 2024 Vigiland. All rights reserved.</p>
		</footer>
	);
};

export default Footer;
