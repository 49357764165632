import React, { FC } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';

// import Advantages from './components/Advantages/Advantages';
// import Description from './components/Description/Description';
// import History from './components/History/History';
// import HowTo from './components/HowTo/HowTo';
// import Footer from '../../../components/Footer/Footer';
// import MobileFooter from '../../../components/Footer/MobileFooter';
// import LandingNavbar from '../../../components/LandingNavbar/LandingNavbar';
// import NavbarComponent from '../NavbarComponent/NavbarComponent';
import SignInModal from '../Modals/SignInModal/SignInModal';
import './styles.scss';

const AboutPage: FC = () => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });
	const location = useLocation();
	const isSignIn = location.pathname.includes('/sign-in');

	return (
		<div
			className={`signInBackgroundWrapper ${
				isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile'
			}`}>
			{/* <NavbarComponent /> */}
			<SignInModal show={isSignIn} />
			<main></main>
		</div>
	);
};

export default AboutPage;
