import { combineReducers, configureStore } from '@reduxjs/toolkit';

import activeMarkerReducer from './reducers/activeMarkerSlice';
import dictionariesReducer from './reducers/DictionariesSlice';
import filtersReducer from './reducers/FiltersSlice';
import objectsReducer from './reducers/ObjectsSlice';
import pageReducer from './reducers/PageSlice';
// import { adminBuildingAPI } from '../services/AdminBuildingListService';
import { objectsAPI } from '../services/ObjectsService';
// import { userAPI } from '../services/UserService';

const rootReducer = combineReducers({
	dictionariesReducer,
	filtersReducer,
	pageReducer,
	objectsReducer,
	activeMarkerReducer,
	// [adminBuildingAPI.reducerPath]: adminBuildingAPI.reducer,
	[objectsAPI.reducerPath]: objectsAPI.reducer,
	// [userAPI.reducerPath]: userAPI.reducer,
});

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware()
				// .concat(adminBuildingAPI.middleware)
				.concat(objectsAPI.middleware),
		// .concat(userAPI.middleware),
	});
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
