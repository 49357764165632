import React, { useEffect, useState } from 'react';

// import dayjs from 'dayjs';
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useLocation, Link } from 'react-router-dom';

import { timezones } from './consts';
import exit from '../../assets/icons/arrow-right-from-bracket.svg';
import arrow from '../../assets/icons/Chevron-down-outline.svg';
import logo from '../../assets/icons/logo_vigiland_1_white 1.svg';
import menu from '../../assets/icons/menu.svg';
import ExitModal from '../Modals/ExitModal/ExitModal';
import MobileMenu from './components/MobileMenu/MobileMenu';
import changeLanguage from '../../utils/changeLanguage';
import './styles.scss';

const NavbarComponent: React.FC = () => {
	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const [showExitModal, setExitModal] = useState<boolean>(false);
	const handleExitModal = () => setExitModal(!showExitModal);
	const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
	const handleMobileMenu = () => setShowMobileMenu(!showMobileMenu);
	const [localTimezone, setLocalTimezone] = useState<string | null>(
		localStorage.getItem('timezone')
	);

	const handleTimezone = (utc: string) => {
		localStorage.setItem('timezone', utc);
		setLocalTimezone(utc);
	};

	const { search } = useLocation();

	const { t, i18n } = useTranslation();

	const [activeLink, setActiveLink] = useState('');

	const location = useLocation();
	const pathname = location.pathname;

	const activeLinkDefiner = (url: string) => {
		if (url.includes('/on-map')) {
			setActiveLink('map');
		}
	};

	useEffect(() => {
		activeLinkDefiner(pathname);
	}, [pathname]);

	return (
		<>
			<ExitModal show={showExitModal} handler={handleExitModal} />
			<MobileMenu show={showMobileMenu} handler={handleMobileMenu} />
			{isDesktop || isTablet ? (
				<header>
					<div
						className={`navbarWrapperBG ${
							pathname.includes('/objects')
								? 'objectPageNavbar'
								: pathname.includes('/on-map')
								? 'mapNavbar'
								: ''
						}`}
					/>
					<Navbar
						className={`navbarComponent ${
							pathname.includes('/objects')
								? 'objectPageNavbar'
								: pathname.includes('/on-map')
								? 'mapNavbar'
								: ''
						}`}>
						<div className='header'>
							<Navbar.Brand href='/client' className='logo'>
								<img src={logo} alt='Logo' className='logoImg' />
								<div className='logoText'>
									<span className='logoName'>Vigiland</span>
									<div className='parserLabel'>parser</div>
								</div>
							</Navbar.Brand>
							<Navbar.Collapse id='basic-navbar-nav'>
								<Nav className='links'>
									<Nav.Link
										as={Link}
										to={`/${search}`}
										className={`${activeLink === '' && 'active'}`}>
										{t('navbar.mainPage')}
									</Nav.Link>
									<Nav.Link
										as={Link}
										to={`/on-map${search}`}
										className={`${activeLink === 'map' && 'active'}`}>
										{t('navbar.onMap')}
									</Nav.Link>
									<Dropdown>
										<Dropdown.Toggle
											id='timezonesDropdown'
											className='timezonesDropdown'>
											{timezones
												.filter((timezone) => timezone.utc === localTimezone)
												.map(
													(timezone) =>
														`UTC ${timezone.utc}, ${t(
															`navbar.timezones.${timezone.city}`
														)}`
												)}
											<img
												src={arrow}
												alt='dropdownArrow'
												className='dropdownArrow'
											/>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{timezones
												.filter((timezone) => timezone.utc !== localTimezone)
												.map((timezone) => (
													<Dropdown.Item
														key={timezone.city}
														className='timezoneBtn'
														onClick={() => handleTimezone(timezone.utc)}>
														{'UTC '}
														{timezone.utc},{' '}
														{t(`navbar.timezones.${timezone.city}`)}
													</Dropdown.Item>
												))}
										</Dropdown.Menu>
									</Dropdown>
									<Dropdown>
										<Dropdown.Toggle id='lngDropdown' className='lngDropdown'>
											{i18n.language.toUpperCase()}
											<img
												src={arrow}
												alt='dropdownArrow'
												className='dropdownArrow'
											/>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{i18n.options.supportedLngs &&
												i18n.options.supportedLngs
													.filter(
														(lng) => lng !== i18n.language && lng !== 'cimode'
													)
													.map((lng) => (
														<Dropdown.Item
															key={lng}
															className='lngBtn'
															onClick={() => changeLanguage(lng)}>
															{lng.toUpperCase()}
														</Dropdown.Item>
													))}
										</Dropdown.Menu>
									</Dropdown>
									<Button className='exitBtn' onClick={handleExitModal}>
										<img src={exit} alt='exitBtn' />
									</Button>
								</Nav>
							</Navbar.Collapse>
						</div>
					</Navbar>
				</header>
			) : (
				<header>
					<div
						className={`navbarWrapperBG ${
							pathname.includes('/objects')
								? 'objectPageNavbar'
								: pathname.includes('/on-map')
								? 'mapNavbar'
								: ''
						}`}
					/>
					<Navbar
						expand='xl'
						className={`d-flex navbarComponent ${
							pathname.includes('/objects')
								? 'objectPageNavbar'
								: pathname.includes('/on-map')
								? 'mapNavbar'
								: ''
						}`}>
						<Navbar.Brand href='/client' className='logo'>
							<img src={logo} alt='Logo' className='logoImg' />
							<div className='logoText'>
								<span className='logoName'>Vigiland</span>
								<div className='parserLabel'>parser</div>
							</div>
						</Navbar.Brand>
						<Button
							type='button'
							variant='transparent'
							className='menuBtn'
							onClick={handleMobileMenu}>
							<img src={menu} alt='menu' />
						</Button>
					</Navbar>
				</header>
			)}
		</>
	);
};

export default NavbarComponent;
