import React from 'react';

import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import './styles.scss';

const MapButton = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<div className='mapBtn'>
			<Button className='openMapBtn' onClick={() => navigate('/on-map')}>
				{t('mainPage.showOnTheMap')}
			</Button>
		</div>
	);
};

export default MapButton;
