import React from 'react';

import {
	components,
	SingleValueProps,
	GroupBase,
	ValueContainerProps,
} from 'react-select';

import { ExtendedSelectProps } from '../../utils/types';

export const CustomSingleValuePrefix = <Option,>({
	children,
	...props
}: SingleValueProps<Option, false, GroupBase<Option>>) => (
	<components.SingleValue {...props}>
		<span className='prefix'>
			{(props.selectProps as ExtendedSelectProps<Option, false>).prefix}
		</span>{' '}
		<span className='selectedOption'>{children}</span>
	</components.SingleValue>
);

/* export const CustomMultiValuePrefix = <Option,>({
	children,
	...props
}: MultiValueProps<Option, true, GroupBase<Option>>) => (
	<components.MultiValue {...props}>
		<span className='prefix'>
			{(props.selectProps as ExtendedSelectProps<Option, true>).prefix}
		</span>{' '}
		<span className='selectedOption'>{children}</span>
	</components.MultiValue>
); */

export const CustomValueContainer = <Option,>({
	children,
	...props
}: ValueContainerProps<Option, true, GroupBase<Option>>) => {
	const { prefix } = props.selectProps as ExtendedSelectProps<Option, true>; // Получаем isFocused через selectProps

	return (
		<components.ValueContainer {...props}>
			{prefix && <span className='prefix'>{prefix}</span>}
			{/* Плейсхолдер отображается только если не в фокусе и нет выбранных значений */}
			{children}
		</components.ValueContainer>
	);
};

export const CustomMultiValueOption = (props: any) => {
	return (
		<components.Option {...props}>
			<div className='selectMultiOption'>
				<span>{props.label}</span>
				<div
					className={`optionCheckbox ${
						props.isSelected ? 'checked' : ''
					}`}></div>
			</div>
		</components.Option>
	);
};
