import { useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from './redux';
// import { filtersSlice } from '../store/reducers/FiltersSlice';

const useSetQueryString = () => {
	const filters = useAppSelector((state) => state.filtersReducer);
	// const dispatch = useAppDispatch();
	// const { setObjectFilters, setMainFilters } = filtersSlice.actions;
	const { search } = useLocation();
	const navigate = useNavigate();

	const previousFiltersRef = useRef(filters);

	useEffect(() => {
		const queryParams = new URLSearchParams(search);

		const filtersChanged =
			JSON.stringify(previousFiltersRef.current) !== JSON.stringify(filters);

		if (filtersChanged) {
			// Убираем activeMarkerId при изменении фильтров
			queryParams.delete('activeMarkerId');

			// Обновляем значение предыдущих фильтров
			previousFiltersRef.current = filters;
		}

		if (filters.land && filters.land.length > 0) {
			queryParams.delete('lands');
			filters.land.forEach((land) => queryParams.append('lands', land));
		} else {
			queryParams.delete('lands');
		}

		if (filters.city && filters.city.length > 0) {
			queryParams.delete('cities');
			filters.city.forEach((city) => queryParams.append('cities', city));
		} else {
			queryParams.delete('cities');
		}

		if (filters.type && filters.type.length > 0) {
			queryParams.delete('types');
			filters.type.forEach((type) => queryParams.append('types', type));
		} else {
			queryParams.delete('types');
		}

		if (filters.area_from && filters.area_from > 0) {
			queryParams.set('area_from', String(filters.area_from));
		} else {
			queryParams.delete('area_from');
		}

		if (filters.area_to && filters.area_to > 0) {
			queryParams.set('area_to', String(filters.area_to));
		} else {
			queryParams.delete('area_to');
		}

		if (filters.price_from && filters.price_from > 0) {
			queryParams.set('price_from', String(filters.price_from));
		} else {
			queryParams.delete('price_from');
		}

		if (filters.price_to && filters.price_to > 0) {
			queryParams.set('price_to', String(filters.price_to));
		} else {
			queryParams.delete('price_to');
		}

		if (filters.terminDates && filters.terminDates.length > 0) {
			queryParams.delete('terminDates');
			filters.terminDates.forEach((terminDate) =>
				queryParams.append('terminDates', terminDate)
			);
		} else {
			queryParams.delete('terminDates');
		}

		if (filters.un_active) {
			queryParams.set('un_active', 'true');
		} else {
			queryParams.delete('un_active');
		}

		navigate({
			search: queryParams.toString(),
		});
	}, [filters, search, navigate]);
};

export default useSetQueryString;
