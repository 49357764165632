import React, { useEffect } from 'react';

import { FormikProps, useFormik } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as yup from 'yup';

import { useApi } from '../../../../contexts/ApiContext';
import {
	CustomMultiValueOption,
	CustomValueContainer,
} from '../../../../hoc/SelectCustoms/SelectCustoms';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useFilter from '../../../../hooks/useFilter';
import { DomainZvgFilter } from '../../../../shared/api/Api';
import {
	fetchTypes,
	fetchCities,
	fetchLands,
} from '../../../../store/reducers/ActionCreators';
import { filtersSlice } from '../../../../store/reducers/FiltersSlice';
import {
	/* handleAreaFrom,
	handleAreaTo, */
	handleClearFilters,
	/* handlePriceFrom,
	handlePriceTo,
	handleSetTypes,
	handleShowTerminal, */
} from '../../../../utils/filtersHandlers';
import { optionsCounter } from '../../../../utils/optionsCounter';
import { terminDatesOptions } from '../../consts';
import './styles.scss';

const ObjectFilters: React.FC<{ mobileMap?: boolean }> = ({ mobileMap }) => {
	const { t } = useTranslation();
	const filters = useAppSelector((state) => state.filtersReducer);
	// const localObjects = useAppSelector((state) => state.objectsReducer);
	const dispatch = useAppDispatch();
	const { getTypes, getLands, getCities } = useApi(); // Достаем getLands из контекста
	const { dictionaries } = useAppSelector((state) => state.dictionariesReducer);
	const { setObjectFilters, setMainFilters, setCities } = filtersSlice.actions;

	// const { search } = useLocation();
	// const navigate = useNavigate();

	const filteredObjects = useFilter();

	/* const landOptions = dictionaries.lands?.map((land) => ({
		value: land,
		label: land.name,
	})); */

	useEffect(() => {
		mobileMap &&
			dictionaries.lands?.length === 0 &&
			dispatch(fetchLands(getLands));
		mobileMap &&
			dictionaries.cities?.length === 0 &&
			dispatch(fetchCities(getCities));
		dispatch(fetchTypes(getTypes));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, getTypes]);

	const validationSchema: yup.ObjectSchema<
		Pick<
			DomainZvgFilter,
			| 'land'
			| 'city'
			| 'area_from'
			| 'area_to'
			| 'price_from'
			| 'price_to'
			| 'type'
			| 'un_active'
		>
	> = yup.object().shape({
		land: yup.array().of(yup.string().defined()), // Строки без `undefined` в массиве
		city: yup.array().of(yup.string().defined()), // Строки без `undefined` в массиве
		type: yup.array().of(yup.string().defined()), // Строки без `undefined` в массиве
		area_from: yup.number(),
		area_to: yup.number(),
		price_from: yup.number(),
		price_to: yup.number(),
		un_active: yup.boolean(),
	});

	const formik: FormikProps<DomainZvgFilter & { terminDates?: string[] }> =
		useFormik<DomainZvgFilter & { terminDates?: string[] }>({
			initialValues: {
				land: filters.land ?? [],
				city: filters.city ?? [],
				type: filters.type ?? [],
				area_from: filters.area_from ?? 0,
				area_to: filters.area_to ?? 0,
				price_from: filters.price_from ?? 0,
				price_to: filters.price_to ?? 0,
				terminDates: filters.terminDates ?? [],
				un_active: filters.un_active ?? false,
			},
			validationSchema,
			enableReinitialize: true,
			onSubmit: (values) => {
				// Фильтруем значения, где value === 0
				/* const filteredValues = Object.fromEntries(
					Object.entries(values).filter(([key, value]) => {
						// Оставляем поля, где значение не равно 0
						// Для массивов и булевых значений оставляем как есть
						return value !== 0 || key === 'type' || key === 'un_active' || key !== 'land' || key !== 'city';
					})
				); */
				const {
					land,
					city,
					area_from,
					area_to,
					price_from,
					price_to,
					un_active,
					type,
					terminDates,
				} = values;

				const objectFilterValues = {
					area_from,
					area_to,
					price_from,
					price_to,
					un_active,
					type,
					terminDates,
				};

				const mainFilterValues = { land, city };

				dispatch(setObjectFilters(objectFilterValues));
				mobileMap && dispatch(setMainFilters(mainFilterValues));
				/* const queryParams = new URLSearchParams(search);
				queryParams.delete('activeMarkerId');
				navigate({
					search: queryParams.toString(),
				}); */
				// console.log('Отправляемые значения:', filteredValues);
			},
		});

	useEffect(() => {
		// Получаем текущие выбранные земли
		const selectedLands = formik.values.land;

		// Фильтруем города, которые относятся к текущим выбранным землям
		const filteredCities = dictionaries.cities?.filter((city) =>
			selectedLands?.includes(city.land as string)
		);

		// Обновляем значение в formik для 'city', удаляя несоответствующие города
		const validCities = formik.values.city?.filter((cityName) =>
			filteredCities?.some((city) => city.name === cityName)
		);

		// Если города изменились, обновляем значение в formik
		if (formik.values.city?.length !== validCities?.length) {
			formik.setFieldValue('city', validCities);
			dispatch(setCities(validCities as string[]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.land, formik, dispatch, setCities]);

	const filteredCities = (() => {
		const cities = dictionaries.cities;
		const selectedLands = formik.values.land;

		if (Array.isArray(cities) && cities.length > 0) {
			if (selectedLands && selectedLands.length > 0) {
				// Фильтруем и сортируем, если земли выбраны
				return cities
					.filter((city) => selectedLands.includes(city.land as string))
					.slice() // shallow copy
					.sort((a, b) => (a.name as string).localeCompare(b.name as string));
			}
			// Если земли не выбраны, сортируем все города
			return cities
				.slice()
				.sort((a, b) => (a.name as string).localeCompare(b.name as string));
		}
		// Если городов нет, возвращаем пустой массив
		return [];
	})();

	return (
		<div className='filters'>
			{mobileMap ? (
				<h3>{t('mainPage.filters.mobileMapTitle')}</h3>
			) : (
				<h3>{t('mainPage.filters.title')}</h3>
			)}
			<Form className='filtersForm' onSubmit={formik.handleSubmit}>
				{mobileMap && (
					<>
						<Select
							isMulti
							hideSelectedOptions={false}
							components={{
								ValueContainer: CustomValueContainer,
								Option: CustomMultiValueOption,
							}}
							options={
								dictionaries.lands
									?.map((land) => {
										const count = optionsCounter(
											filteredObjects,
											'land',
											land.code
										);
										return {
											value: land,
											label: count > 0 ? `${land.name} (${count})` : land.name,
											count, // добавляем count для сортировки
										};
									})
									.sort((a, b) => {
										// Если count отличается, то элементы с count > 0 идут перед count === 0
										if (a.count === 0 && b.count !== 0) return 1;
										if (a.count !== 0 && b.count === 0) return -1;
										// Если count равен, сортируем по label в алфавитном порядке
										return (a.label as string).localeCompare(b.label as string);
									})
									.map(({ value, label }) => ({ value, label })) // Убираем count из конечного результата
							}
							//@ts-ignore
							prefix={`${t('mainPage.filters.prefixes.land')}:`}
							placeholder={t('mainPage.filters.placeholders.all')}
							value={dictionaries.lands
								?.filter((land) =>
									formik.values.land?.includes(land.code as string)
								)
								.map((land) => ({ value: land, label: land.name }))}
							className='landSelector'
							classNamePrefix='landSelector'
							onChange={(selectedOptions) =>
								formik.setFieldValue(
									'land',
									selectedOptions.map((option) => option.value.code)
								)
							}
						/>
						<Select
							isDisabled={
								formik.values.land?.length === 0 || filteredCities.length === 0
							}
							isMulti
							hideSelectedOptions={false}
							components={{
								ValueContainer: CustomValueContainer,
								Option: CustomMultiValueOption,
							}}
							options={
								filteredCities
									.map((city) => {
										const count = optionsCounter(
											filteredObjects,
											'city',
											city.name
										);
										return {
											value: city,
											label: count > 0 ? `${city.name} (${count})` : city.name,
											count, // добавляем count для сортировки
										};
									})
									.sort((a, b) => {
										// Если count отличается, то элементы с count > 0 идут перед count === 0
										if (a.count === 0 && b.count !== 0) return 1;
										if (a.count !== 0 && b.count === 0) return -1;
										// Если count равен, сортируем по label в алфавитном порядке
										return (a.label as string).localeCompare(b.label as string);
									})
									.map(({ value, label }) => ({ value, label })) // Убираем count из конечного результата
							}
							//@ts-ignore
							prefix={`${t('mainPage.filters.prefixes.city')}:`}
							placeholder={
								filteredCities.length === 0
									? t('mainPage.filters.placeholders.noCities')
									: t('mainPage.filters.placeholders.all')
							}
							value={filteredCities
								.filter((city) =>
									formik.values.city?.includes(city.name as string)
								)
								.map((city) => ({ value: city, label: city.name }))}
							className='citySelector'
							classNamePrefix='citySelector'
							onChange={(selectedOptions) =>
								formik.setFieldValue(
									'city',
									selectedOptions.map((option) => option.value.name)
								)
							}
						/>
					</>
				)}
				<Select
					isMulti
					hideSelectedOptions={false}
					components={{
						ValueContainer: CustomValueContainer, // Добавляем кастомный контейнер с префиксом
						Option: CustomMultiValueOption,
					}}
					options={
						dictionaries.types
							?.map((type) => {
								const count = optionsCounter(
									filteredObjects,
									'types',
									type.code
								);
								return {
									value: type,
									label: count > 0 ? `${type.name} (${count})` : type.name,
									count, // добавляем count для сортировки
								};
							})
							.sort((a, b) => {
								// Если count отличается, то элементы с count > 0 идут перед count === 0
								if (a.count === 0 && b.count !== 0) return 1;
								if (a.count !== 0 && b.count === 0) return -1;
								// Если count равен, сортируем по label в алфавитном порядке
								return (a.label as string).localeCompare(b.label as string);
							})
							.map(({ value, label }) => ({ value, label })) // Убираем count из конечного результата
					}
					//@ts-ignore
					prefix={`${t('mainPage.filters.prefixes.type')}:`}
					placeholder={t('mainPage.filters.placeholders.all')}
					value={dictionaries.types
						?.filter((type) =>
							formik.values.type?.includes(type.code as string)
						) // Соответствующие выбранным кодам земли
						.map((type) => ({ value: type, label: type.name }))} // Преобразование для Select
					className='typeSelector'
					classNamePrefix='typeSelector'
					onChange={(selectedOptions) =>
						formik.setFieldValue(
							'type',
							selectedOptions.map((option) => option.value.name)
						)
					}
				/>
				<div className='input-wrapper'>
					<label className='form-label'>
						{t('mainPage.filters.labels.area')}
					</label>
					<div className='formsRow'>
						<Form.Control
							name='area_from'
							placeholder={t('mainPage.filters.placeholders.area_from')}
							className='custom-form-control'
							value={
								formik.values.area_from === 0 ? '' : formik.values.area_from
							}
							type='number'
							min={30}
							max={1000}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<Form.Control
							name='area_to'
							placeholder={t('mainPage.filters.placeholders.to')}
							className='custom-form-control'
							value={formik.values.area_to === 0 ? '' : formik.values.area_to}
							type='number'
							min={formik.values.area_from}
							max={1000}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</div>
				</div>
				<div className='input-wrapper'>
					<label className='form-label'>
						{t('mainPage.filters.labels.price')}
					</label>
					<div className='formsRow'>
						<Form.Control
							name='price_from'
							placeholder={t('mainPage.filters.placeholders.price_from')}
							className='custom-form-control'
							value={
								formik.values.price_from === 0 ? '' : formik.values.price_from
							}
							type='number'
							min={100}
							max={1000000}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
						<Form.Control
							name='price_to'
							placeholder={t('mainPage.filters.placeholders.to')}
							className='custom-form-control'
							value={formik.values.price_to === 0 ? '' : formik.values.price_to}
							type='number'
							min={formik.values.price_from}
							max={1000000}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</div>
				</div>
				<Select
					isMulti
					hideSelectedOptions={false}
					components={{
						ValueContainer: CustomValueContainer, // Добавляем кастомный контейнер с префиксом
						Option: CustomMultiValueOption,
					}}
					options={terminDatesOptions}
					value={terminDatesOptions.filter((option) =>
						formik.values.terminDates?.includes(option.value)
					)}
					//@ts-ignore
					prefix={`${t('mainPage.filters.prefixes.terminDates')}:`}
					placeholder={t('mainPage.filters.placeholders.all')}
					className='terminDateSelector'
					classNamePrefix='terminDateSelector'
					onChange={(selectedOptions) =>
						formik.setFieldValue(
							'terminDates',
							selectedOptions.map((option) => option.value)
						)
					}
				/>
				<div className='showTerminalCheckbox'>
					<input
						className='form-check-input'
						type='checkbox'
						id='showTerminalCheck'
						checked={formik.values.un_active}
						onChange={(e) =>
							formik.setFieldValue('un_active', e.target.checked)
						}
					/>
					<label htmlFor='showTerminalCheck' className='form-check-label'>
						{t('mainPage.filters.labels.showTerminalCheck')}
					</label>
				</div>
				<div className='formBtns'>
					<Button type='submit' className='searchBtn'>
						{t('mainPage.filters.btns.search')}
					</Button>
					<Button
						className='clearFiltersBtn'
						onClick={(e) => {
							handleClearFilters(formik, dispatch);
							/* const queryParams = new URLSearchParams(search);
							queryParams.delete('activeMarkerId');
							navigate({
								search: queryParams.toString(),
							}); */
						}}>
						{t('mainPage.filters.btns.clearFilters')}
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default ObjectFilters;
