import { Dispatch, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit';
import { FormikProps } from 'formik';
// import { MultiValue } from 'react-select';

import {
	/* DomainCity,
	DomainLand,
	DomainTypes, */
	DomainZvgFilter,
} from '../shared/api/Api';
import { filtersSlice } from '../store/reducers/FiltersSlice';

const {
	/* setAreaFrom,
	setAreaTo,
	setPriceFrom,
	setPriceTo,
	setShowTerminal, */
	clearFilters,
	/* setLands,
	setCities,
	setTypes, */
} = filtersSlice.actions;

/* export const handleAreaFrom = (
	formik: FormikProps<DomainZvgFilter>,
	value: number,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue('area_from', value);
	dispatch(setAreaFrom(value));
};

export const handleAreaTo = (
	formik: FormikProps<DomainZvgFilter>,
	value: number,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue('area_to', value);
	dispatch(setAreaTo(value));
};

export const handlePriceFrom = (
	formik: FormikProps<DomainZvgFilter>,
	value: number,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue('price_from', value);
	dispatch(setPriceFrom(value));
};

export const handlePriceTo = (
	formik: FormikProps<DomainZvgFilter>,
	value: number,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue('price_to', value);
	dispatch(setPriceTo(value));
};

export const handleShowTerminal = (
	formik: FormikProps<DomainZvgFilter>,
	value: boolean,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue('un_active', value);
	dispatch(setShowTerminal(value));
}; */

export const handleClearFilters = (
	formik: FormikProps<DomainZvgFilter>,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.resetForm(); // Сбрасываем форму
	dispatch(clearFilters()); // Сбрасываем фильтры в состоянии
};

/* export const handleSetLands = (
	formik: FormikProps<DomainZvgFilter>,
	value: MultiValue<{
		value: DomainLand;
		label: string | undefined;
	}>,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue(
		'land',
		value.map((option) => option.value.name)
	);
	dispatch(setLands(value.map((option) => option.value.code as string))); // Сбрасываем фильтры в состоянии
};

export const handleSetCities = (
	formik: FormikProps<DomainZvgFilter>,
	value: MultiValue<{
		value: DomainCity;
		label: string | undefined;
	}>,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue(
		'city',
		value.map((option) => option.value.name)
	);
	dispatch(setCities(value.map((option) => option.value.name as string))); // Сбрасываем фильтры в состоянии
};

export const handleSetTypes = (
	formik: FormikProps<DomainZvgFilter>,
	value: MultiValue<{
		value: DomainTypes;
		label: string | undefined;
	}>,
	dispatch: ThunkDispatch<
		{
			filtersReducer: DomainZvgFilter;
		},
		undefined,
		UnknownAction
	> &
		Dispatch<UnknownAction>
) => {
	formik.setFieldValue(
		'type',
		value.map((option) => option.value.name)
	);
	dispatch(setTypes(value.map((option) => option.value.code as string))); // Сбрасываем фильтры в состоянии
}; */

// export const clearActiveMarker = (queryParams: URLSearchParams) => queryParams.delete('activeMarkerId');
