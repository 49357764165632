import React /*  useEffect, useState */ from 'react';

import ReactPaginate from 'react-paginate';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { pageSlice } from '../../../../store/reducers/PageSlice';
import './styles.scss';

const Pagination: React.FC = () => {
	const { setPage } = pageSlice.actions;
	const dispatch = useAppDispatch();
	const { page, total, limit } = useAppSelector((state) => state.pageReducer);
	const pageCount = Math.ceil(total / limit);

	//@ts-ignore
	const handlePageClick = (event) => {
		const newOffset = (event.selected * limit) % total;
		// console.log(
		// 	`User requested page number ${event.selected}, which is offset ${newOffset}`
		// );
		dispatch(setPage(event.selected));
	};

	return (
		<>
			<ReactPaginate
				breakLabel='...'
				nextLabel=''
				onPageChange={handlePageClick}
				pageRangeDisplayed={3}
				pageCount={pageCount}
				previousLabel=''
				className='pagination'
				forcePage={page}
			/>
		</>
	);
};

export default Pagination;
