import { AppDispatch } from '../store';
import { dictionariesSlice } from './DictionariesSlice';
import { DomainCity, DomainLand, DomainTypes } from '../../shared/api/Api';

export const fetchLands =
	(getLands: () => Promise<DomainLand[]>) => async (dispatch: AppDispatch) => {
		try {
			dispatch(dictionariesSlice.actions.landsFetching());
			const response: DomainLand[] = await getLands();
			dispatch(dictionariesSlice.actions.landsFetchingSuccess(response));
		} catch (e: any) {
			dispatch(dictionariesSlice.actions.landsFetchingError(e.message));
		}
	};

export const fetchCities =
	(getCities: () => Promise<DomainCity[]>) => async (dispatch: AppDispatch) => {
		try {
			dispatch(dictionariesSlice.actions.citiesFetching());
			const response: DomainCity[] = await getCities();
			dispatch(dictionariesSlice.actions.citiesFetchingSuccess(response));
		} catch (e: any) {
			dispatch(dictionariesSlice.actions.citiesFetchingError(e.message));
		}
	};

export const fetchTypes =
	(getTypes: () => Promise<DomainTypes[]>) => async (dispatch: AppDispatch) => {
		try {
			dispatch(dictionariesSlice.actions.typesFetching());
			const response: DomainTypes[] = await getTypes();
			dispatch(dictionariesSlice.actions.typesFetchingSuccess(response));
		} catch (e: any) {
			dispatch(dictionariesSlice.actions.typesFetchingError(e.message));
		}
	};
