import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import MainPage from '../components/MainPage/MainPage';
import MapPage from '../components/MapPage/MapPage';
import ObjectPage from '../components/ObjectPage/ObjectPage';
import SignIn from '../components/SignIn/SignIn';
import { useApi } from '../contexts/ApiContext';

const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 100);
	}, [pathname]);

	return null;
};

export { ScrollToTop };

const NotFoundRedirect = () => {
	const { user } = useApi();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const hasNavigated = React.useRef(false); // Используем ref для предотвращения повторных вызовов навигации и тостов

	useEffect(() => {
		if (!hasNavigated.current && pathname !== '/' && pathname !== '/sign-in') {
			toast.error(t('toasts.error.noPage'));
			hasNavigated.current = true; // Устанавливаем флаг после первого вызова
			if (user) {
				navigate('/');
			} else {
				navigate('/sign-in');
				toast.error(t('toasts.error.unauthorized'));
			}
		}
	}, [pathname, navigate, t, user]);

	return null;
};

const RoutesList = () => {
	const { user, error } = useApi();
	const { pathname } = useLocation();

	if (error === 'Internal server error') {
		return (
			<div className='errorText'>
				Sorry, the internal server error occured. Try again later.
			</div>
		);
	} else if (error !== 'Unauthorized' && !user && pathname !== '/sign-in') {
		return <div className='loadingText'>Loading user...</div>;
	}

	return (
		<Routes>
			<Route path='/' element={user ? <MainPage /> : <SignIn />} />
			<Route path='/sign-in' element={<SignIn />} />
			<Route
				path='/objects/:objectId'
				element={user ? <ObjectPage /> : <SignIn />}
			/>
			<Route path='/on-map' element={user ? <MapPage /> : <SignIn />} />
			<Route path='*' element={<NotFoundRedirect />} />
		</Routes>
	);
};

export default RoutesList;
