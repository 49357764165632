import { DomainZvgObject } from '../shared/api/Api';

export const optionsCounter = (
	objects: DomainZvgObject[],
	filterName: 'city' | 'land' | 'types',
	code: string | undefined
) => {
	if (code && objects) {
		return objects.reduce((count, object) => {
			const filterValue = object.meta?.[filterName];

			if (filterName === 'types' && Array.isArray(filterValue) && code) {
				if (filterValue.includes(code)) {
					return count + 1;
				}
			} else if (filterValue === code) {
				return count + 1;
			}

			return count;
		}, 0);
	}
	return 0;
};
