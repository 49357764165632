import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import './styles.scss';

const Timer: React.FC<{
	isTerminated: boolean;
	termin_at: string;
	numericOffset: number;
}> = ({ isTerminated, termin_at, numericOffset }) => {
	const { t } = useTranslation();

	const [timeRemaining, setTimeRemaining] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	});

	useEffect(() => {
		if (isTerminated) {
			setTimeRemaining({ days: 0, hours: 0, minutes: 0, seconds: 0 });
			return; // Don't start the interval
		}

		const updateRemainingTime = () => {
			const terminAt = dayjs.utc(termin_at).utcOffset(numericOffset); // Учитываем часовой пояс
			const now = dayjs().utcOffset(numericOffset); // Текущее время с учётом часового пояса
			const duration = dayjs.duration(terminAt.diff(now)); // Разница между текущим временем и termin_at

			setTimeRemaining({
				days: Math.floor(duration.asDays()),
				hours: duration.hours(),
				minutes: duration.minutes(),
				seconds: duration.seconds(),
			});
		};

		updateRemainingTime(); // Обновляем время при монтировании компонента

		const intervalId = setInterval(updateRemainingTime, 1000); // Обновляем каждую секунду

		return () => clearInterval(intervalId); // Очищаем интервал при размонтировании
	}, [numericOffset, termin_at, isTerminated]);

	return (
		<div className='timer'>
			<div className='block'>
				<p>{timeRemaining.days.toString().padStart(2, '0')}</p>
				<p>
					{t('objectPage.timer.days', {
						count: timeRemaining.days,
					})}
				</p>
			</div>
			<span className='dots'>:</span>
			<div className='block'>
				<p>{timeRemaining.hours.toString().padStart(2, '0')}</p>
				<p>
					{t('objectPage.timer.hours', {
						count: timeRemaining.hours,
					})}
				</p>
			</div>
			<span className='dots'>:</span>
			<div className='block'>
				<p>{timeRemaining.minutes.toString().padStart(2, '0')}</p>
				<p>
					{t('objectPage.timer.minutes', {
						count: timeRemaining.minutes,
					})}
				</p>
			</div>
			<span className='dots'>:</span>
			<div className='block'>
				<p>{timeRemaining.seconds.toString().padStart(2, '0')}</p>
				<p>
					{t('objectPage.timer.seconds', {
						count: timeRemaining.seconds,
					})}
				</p>
			</div>
		</div>
	);
};

export default Timer;
