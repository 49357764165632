import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { useAppDispatch } from './redux';
import { filtersSlice } from '../store/reducers/FiltersSlice';

const useGetQueryString = () => {
	// const filters = useAppSelector((state) => state.filtersReducer);
	const dispatch = useAppDispatch();
	const { setObjectFilters, setMainFilters } = filtersSlice.actions;
	const { search } = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(search);

		const landsParam = queryParams.getAll('lands');
		const citiesParam = queryParams.getAll('cities');

		const typesParam = queryParams.getAll('types');
		const areaFromParam = queryParams.get('area_from');
		const areaToParam = queryParams.get('area_to');
		const priceFromParam = queryParams.get('price_from');
		const priceToParam = queryParams.get('price_to');
		const terminDatesParam = queryParams.getAll('terminDates');
		const un_activeParam = queryParams.get('un_active');

		dispatch(setMainFilters({ land: landsParam, city: citiesParam }));
		dispatch(
			setObjectFilters({
				type: typesParam,
				area_from: Number(areaFromParam),
				area_to: Number(areaToParam),
				price_from: Number(priceFromParam),
				price_to: Number(priceToParam),
				terminDates: terminDatesParam,
				un_active: Boolean(un_activeParam),
			})
		);
	}, [dispatch, search, setMainFilters, setObjectFilters]);
};

export default useGetQueryString;
