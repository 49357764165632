import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DomainZvgFilter } from '../../shared/api/Api';

const initialState: DomainZvgFilter & { terminDates?: string[] } = {
	land: [],
	city: [],
	type: [],
	area_from: 0,
	area_to: 0,
	price_from: 0,
	price_to: 0,
	terminDates: [],
	un_active: false,
};

export const filtersSlice = createSlice({
	name: 'filters',
	initialState,
	reducers: {
		/* setAreaFrom(state, action: PayloadAction<number>) {
			state.area_from = action.payload;
		},
		setAreaTo(state, action: PayloadAction<number>) {
			state.area_to = action.payload;
		},
		setPriceFrom(state, action: PayloadAction<number>) {
			state.price_from = action.payload;
		},
		setPriceTo(state, action: PayloadAction<number>) {
			state.price_to = action.payload;
		},
		setShowTerminal(state, action: PayloadAction<boolean>) {
			state.un_active = action.payload;
		},
		setLands(state, action: PayloadAction<string[]>) {
			state.land = action.payload;
		},
		setCities(state, action: PayloadAction<string[]>) {
			state.city = action.payload;
		},
		setTypes(state, action: PayloadAction<string[]>) {
			state.type = action.payload;
		}, */
		setMainFilters(
			state,
			action: PayloadAction<Pick<DomainZvgFilter, 'land' | 'city'>>
		) {
			state.land = action.payload.land;
			state.city = action.payload.city;
		},
		setLands(state, action: PayloadAction<string[]>) {
			state.land = action.payload;
		},
		setCities(state, action: PayloadAction<string[]>) {
			state.city = action.payload;
		},
		setObjectFilters(
			state,
			action: PayloadAction<
				Pick<
					DomainZvgFilter,
					| 'area_from'
					| 'area_to'
					| 'price_from'
					| 'price_to'
					| 'un_active'
					| 'type'
				> & { terminDates?: string[] }
			>
		) {
			state.type = action.payload.type;
			state.area_from = action.payload.area_from;
			state.area_to = action.payload.area_to;
			state.price_from = action.payload.price_from;
			state.price_to = action.payload.price_to;
			state.terminDates = action.payload.terminDates;
			state.un_active = action.payload.un_active;
		},
		clearFilters(state) {
			Object.assign(state, {
				land: [],
				city: [],
				type: [],
				area_from: 0,
				area_to: 0,
				price_from: 0,
				price_to: 0,
				terminDates: [],
				un_active: false,
			});
		},
	},
});

export default filtersSlice.reducer;
