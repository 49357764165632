const en = {
	translation: {
		navbar: {
			mainPage: 'Home',
			onMap: 'Listings on the map',
			timezones: {
				Azores: 'Azores',
				London: 'London',
				Paris: 'Paris',
				Berlin: 'Berlin',
				Moscow: 'Moscow',
				AbuDhabi: 'Abu Dhabi',
			},
		},
		toasts: {
			error: {
				noPage: 'The page you are looking for does not exist',
				unauthorized: 'You need to log in first',
			},
		},
		modals: {
			signIn: {
				title: 'Sign in to your account',
				description: 'Vigiland Parser',
				placeholders: {
					email: 'Enter your email',
					password: 'Password',
				},
				rememberMeCheck: 'Remember me',
				signInBtn: 'Sign in',
				errors: {
					email: {
						incorrectFormat: 'The email must be correct',
						required: 'Enter your email',
						maxLength: 'Email is too long',
						minLength: 'Email is too short',
					},
					password: {
						required: 'Enter your password',
						maxLength: 'Password is too long',
						minLength: 'Password is too short',
					},
					confirmPassword: {
						notOneOf: 'Passwords do not match',
					},
					policyAgreement: {
						notTrue: 'Accept the privacy policy',
					},
				},
			},
			exit: {
				title: 'Do you want to log out?',
				cancelBtn: 'Cancel',
				exitBtn: 'Log out',
			},
		},
		mainPage: {
			mainFilter: {
				searchBtn: 'Search',
			},
			filters: {
				title: 'Filters by object',
				mobileMapTitle: 'Filters',
				labels: {
					area: 'Area (m²)',
					price: 'Price (€)',
					showTerminalCheck: 'Show expired',
				},
				placeholders: {
					area_from: 'from',
					price_from: 'from',
					to: 'to',
					all: 'All',
					noCities: 'No available cities',
				},
				prefixes: {
					land: 'Land',
					city: 'City',
					type: 'Type',
					types: 'Types',
					terminDates: 'Termin dates',
				},
				terminDatesLabels: {
					0: 'Current month',
					1: 'Current and next month',
					2: 'Current and next 2 months',
					3: 'Current and next 3 months',
				},
				sortByLabels: {
					dateFromUp: 'Newest first',
					dateFromDown: 'Oldest first',
					priceFromUp: 'Price descending',
					priceFromDown: 'Price ascending',
					landFromUp: 'By region (A-Z)',
					landFromDown: 'By region (Z-A)',
					cityFromUp: 'By city (A-Z)',
					cityFromDown: 'By city (Z-A)',
				},
				btns: {
					search: 'Search',
					clearFilters: 'Reset filters',
				},
			},
			objectFrom: 'From',
			objectTo: 'to',
			objectOf: 'listing out of',
			sortBy: 'Sort by:',
			showOnTheMap: 'Show on the map',
			toTop: 'Back to top',
		},
		objectCard: {
			in: 'in',
			onGoogleMap: 'On Google Map',
		},
		objectPage: {
			timer: {
				days_one: 'day',
				days: 'days',
				hours_one: 'hour',
				hours: 'hours',
				minutes_one: 'minute',
				minutes: 'minutes',
				seconds_one: 'second',
				seconds: 'seconds',
			},
			titles: {
				createAt: 'Creation date:',
				terminAt: 'Auction date:',
				actualAt: 'Last Updated:',
				description: 'Object description',
				price: 'Price',
				files: 'Additional files for the object',
				auction: 'Auction information',
				terminated: 'AUCTION TERMINATED',
			},
			tables: {
				type: 'Type:',
				area: 'Living area:',
				meters: 'm²',
				land: 'Land:',
				city: 'City:',
				address: 'Address:',
				googleMap: 'Google Map:',
				postalCode: 'Postal code:',
				yearOfConstruction: 'Year of construction:',
				numberOfRooms: 'Number of rooms:',
				numberOfApartments: 'Number of apartments:',
				estimatedValue: 'Estimated value:',
				quality: 'Quality rating:',
				landArea: 'Land available:',
				yes: 'Yes',
				// landArea: 'Land area:',
				level: 'Floor:',
				totalLevel: 'Total floors:',
				balcony: 'Balcony:',
				heating: 'Heating:',
				parking: 'Parking:',
				wallMaterial: 'Wall material:',
				date: 'Date:',
				price: 'Estimated price:',
				auctionAddress: 'Auction address:',
				website: 'Website:',
				auctionRoom: 'Room:',
				auctionType: 'Auction type:',
			},
			btns: {
				more: 'Show more',
				less: 'Show less',
			},
		},
		onMap: {
			backBtn: 'Back to list',
		},
		footer: {
			rights: '© 2024 Vigiland. All rights reserved.',
		},
	},
};

export default en;
