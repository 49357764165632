import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DomainCity, DomainLand, DomainTypes } from '../../shared/api/Api';

export interface DictionariesState {
	dictionaries: {
		lands?: DomainLand[];
		cities?: DomainCity[];
		types?: DomainTypes[];
	};
	isLoading: boolean;
	error: string;
}

const initialState: DictionariesState = {
	dictionaries: {
		lands: JSON.parse(localStorage.getItem('landsDictionary') ?? '[]'),
		cities: JSON.parse(localStorage.getItem('citiesDictionary') ?? '[]'),
		types: JSON.parse(localStorage.getItem('typesDictionary') ?? '[]'),
	},
	isLoading: false,
	error: '',
};

export const dictionariesSlice = createSlice({
	name: 'dictionaries',
	initialState,
	reducers: {
		landsFetching(state) {
			state.isLoading = true;
		},
		landsFetchingSuccess(state, action: PayloadAction<DomainLand[]>) {
			state.isLoading = false;
			state.error = '';
			state.dictionaries.lands = action.payload;
			localStorage.setItem('landsDictionary', JSON.stringify(action.payload));
		},
		landsFetchingError(state, action: PayloadAction<string>) {
			state.isLoading = false;
			state.error = action.payload;
		},
		citiesFetching(state) {
			state.isLoading = true;
		},
		citiesFetchingSuccess(state, action: PayloadAction<DomainCity[]>) {
			state.isLoading = false;
			state.error = '';
			state.dictionaries.cities = action.payload;
			localStorage.setItem('citiesDictionary', JSON.stringify(action.payload));
		},
		citiesFetchingError(state, action: PayloadAction<string>) {
			state.isLoading = false;
			state.error = action.payload;
		},
		typesFetching(state) {
			state.isLoading = true;
		},
		typesFetchingSuccess(state, action: PayloadAction<DomainTypes[]>) {
			state.isLoading = false;
			state.error = '';
			state.dictionaries.types = action.payload;
			localStorage.setItem('typesDictionary', JSON.stringify(action.payload));
		},
		typesFetchingError(state, action: PayloadAction<string>) {
			state.isLoading = false;
			state.error = action.payload;
		},
	},
});

export default dictionariesSlice.reducer;
