/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface DomainCity {
  code?: string;
  land?: string;
  name?: string;
}

export interface DomainGeoData {
  lat?: number;
  lon?: number;
}

export interface DomainLand {
  code?: string;
  name?: string;
}

export interface DomainPage {
  limit?: number;
  list?: any;
  page?: number;
  total?: number;
}

export interface DomainPageRequest {
  limit?: number;
  page?: number;
}

export interface DomainTypes {
  code?: string;
  name?: string;
}

export interface DomainZvgAuctionMeta {
  /** адрес */
  address?: string;
  /** город */
  city?: string;
  /** ссылка на карту */
  geo_link?: string;
  /** ссылка на суд */
  gericht_link?: string;
  /** почтовый индекс */
  postal_code?: string;
  /** цена */
  price?: number;
  /** комната */
  room?: string;
  /** тип */
  type?: string;
}

export interface DomainZvgFile {
  /** id of zvg-object */
  data_object_id?: number;
  /** id of file */
  id?: number;
  /** path to markdown file (не используется) */
  md_path?: string;
  /** name of file (without extension) */
  name?: string;
  /** path to pdf-file (не используется) */
  path?: string;
  /** size of file */
  size?: number;
  /** status 0-not downloaded, 1-downloaded, 2-converted to markdown */
  status?: number;
  /** url to file */
  url?: string;
}

export interface DomainZvgFilter {
  area_from?: number;
  area_to?: number;
  city?: string[];
  land?: string[];
  price_from?: number;
  price_to?: number;
  type?: string[];
  un_active?: boolean;
}

export interface DomainZvgImage {
  /** id of zvg-object */
  data_object_id?: number;
  /** id of zvg-file */
  file_id?: number;
  /** id of image */
  id?: number;
  /** path to image file (не используется) */
  path?: string;
  /** primary tags of image */
  primary_tags?: string;
  /** secondary tags of image */
  secondary_tags?: string;
  /** status 0-created, 2-tagged */
  status?: number;
  /** weight of image */
  weight?: number;
}

export interface DomainZvgMdJson {
  /** id of zvg-object */
  data_object_id?: number;
  /** id of markdown */
  id?: number;
  /** json data */
  json_data?: string;
  /** status 0-created */
  status?: number;
}

export interface DomainZvgObject {
  /** активен ли объект */
  active?: boolean;
  /** дата актуальности */
  aktuality_at?: string;
  /** в архиве ли объект */
  archive?: boolean;
  /** мета-данные аукциона */
  auction_meta?: DomainZvgAuctionMeta;
  /** время создания */
  created_at?: string;
  /** файлы объекта */
  files?: DomainZvgFile[];
  /** id of object */
  id?: number;
  /** изображения объекта */
  images?: DomainZvgImage[];
  /** json-данные объекта */
  md_jsons?: DomainZvgMdJson[];
  /** мета-данные объекта */
  meta?: DomainZvgObjectMeta;
  /** текстовые мета-данные объекта */
  meta_texts?: DomainZvgObjectMetaText[];
  /** markdown-данные объекта */
  object_md?: string;
  /** referer */
  referer?: string;
  /** количество попыток загрузки */
  retry?: number;
  /** id of object on site '0002 K 0052/2023' */
  site_id?: string;
  /** статус объекта */
  status?: DomainZvgObjectStatus;
  /** дата торгов (Termin) */
  termin_at?: string;
  /** время последнего обновления */
  updated_at?: string;
  /** url объекта */
  url?: string;
  /** хэш url */
  url_id?: string;
}

export interface DomainZvgObjectMeta {
  /** адрес */
  address?: string;
  /** площадь */
  area?: number;
  /** город */
  city?: string;
  /** оценочная стоимость */
  estimated_value?: number;
  /** геоданные */
  geo?: DomainGeoData;
  /** земля (регион) */
  land?: string;
  /** площадь участка */
  land_area?: number;
  /** этаж */
  level?: number;
  /** название */
  name?: string;
  /** количество квартир (для многоквартирного дома) */
  number_of_apartments?: number;
  /** количество комнат */
  number_of_rooms?: number;
  /** почтовый индекс */
  postal_code?: string;
  /** качество 0-10 */
  quality?: number;
  /** комната */
  room?: string;
  /** общее количество этажей */
  total_level?: number;
  /** Тип объекта (не немецком языке) */
  type?: string;
  /** Типы объекта */
  types?: string[];
  /** год постройки */
  year_of_construction?: number;
}

export interface DomainZvgObjectMetaText {
  /** балкон */
  balcony?: string;
  /** id of zvg-object */
  data_object_id?: number;
  /** описание */
  description?: string;
  /** отопление */
  heating?: string;
  /** id of text */
  id?: number;
  /** язык текста */
  lang?: string;
  /** название */
  name?: string;
  /** парковка */
  parking?: string;
  /** тип объекта */
  type?: string;
  /** материал стен */
  wall_material?: string;
}

export enum DomainZvgObjectStatus {
  ZvgNew = 0,
}

export interface DomainZvgOrder {
  by?: string;
  reverse?: boolean;
}

export interface TransportListRequest {
  filter?: DomainZvgFilter;
  language?: string;
  order?: DomainZvgOrder;
  page: DomainPageRequest;
}

export interface TransportUserProfile {
  admin?: boolean;
  email?: string;
  id?: number;
  language?: string;
  name?: string;
  phone?: string;
  region?: string;
  time_zone?: string;
}

export interface TransportUserSignInRequest {
  /**
   * @minLength 3
   * @maxLength 150
   */
  email: string;
  /**
   * @minLength 6
   * @maxLength 100
   */
  password: string;
  /** @example false */
  remember?: boolean;
}

export interface TransportWebError {
  err?: string;
}

export interface TransportWebOK {
  ok?: boolean;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Vigiland AI API
 * @version 1.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @contact API Support <support@swagger.io> (http://www.swagger.io/support)
 *
 * Vigiland AI api backend server
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  a = {
    /**
     * @description Get user profile
     *
     * @tags users
     * @name GetA
     * @summary Get profile
     * @request GET:/a/me
     */
    getA: (params: RequestParams = {}) =>
      this.request<TransportUserProfile, TransportWebError>({
        path: `/a/me`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Update user profile
     *
     * @tags users
     * @name PostA
     * @summary Update user profile
     * @request POST:/a/me
     */
    postA: (request: TransportUserProfile, params: RequestParams = {}) =>
      this.request<TransportUserProfile, TransportWebError>({
        path: `/a/me`,
        method: "POST",
        body: request,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Sign out
     *
     * @tags users
     * @name SignoutList
     * @summary Sign out
     * @request GET:/a/signout
     */
    signoutList: (params: RequestParams = {}) =>
      this.request<TransportWebOK, TransportWebError>({
        path: `/a/signout`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get full building list
     *
     * @tags zvg_object
     * @name GetA2
     * @summary Get full building list
     * @request GET:/a/zvg/
     * @originalName getA
     * @duplicate
     */
    getA2: (params: RequestParams = {}) =>
      this.request<DomainZvgObject[], TransportWebError>({
        path: `/a/zvg/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get building list
     *
     * @tags zvg_object
     * @name ZvgObjectList
     * @summary Get building list
     * @request GET:/a/zvg_object/
     */
    zvgObjectList: (params: RequestParams = {}) =>
      this.request<DomainPage, TransportWebError>({
        path: `/a/zvg_object/`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get building list
     *
     * @tags zvg_object
     * @name ZvgObjectCreate
     * @summary Get building list
     * @request POST:/a/zvg_object/
     */
    zvgObjectCreate: (request: TransportListRequest, params: RequestParams = {}) =>
      this.request<DomainPage, TransportWebError>({
        path: `/a/zvg_object/`,
        method: "POST",
        body: request,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get building detail
     *
     * @tags zvg_object
     * @name ZvgObjectDetail
     * @summary Get building detail
     * @request GET:/a/zvg_object/{id}
     */
    zvgObjectDetail: (id: string, params: RequestParams = {}) =>
      this.request<DomainZvgObject, TransportWebError>({
        path: `/a/zvg_object/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  dictionary = {
    /**
     * @description Get cities list
     *
     * @tags dictionary
     * @name CitiesList
     * @summary Get cities list
     * @request GET:/dictionary/cities
     */
    citiesList: (
      query?: {
        /** land code */
        land_code?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DomainCity[], TransportWebError>({
        path: `/dictionary/cities`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Get lands list
     *
     * @tags dictionary
     * @name LandsList
     * @summary Get lands list
     * @request GET:/dictionary/lands
     */
    landsList: (params: RequestParams = {}) =>
      this.request<DomainLand[], TransportWebError>({
        path: `/dictionary/lands`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get types list
     *
     * @tags dictionary
     * @name TypesList
     * @summary Get types list
     * @request GET:/dictionary/types
     */
    typesList: (params: RequestParams = {}) =>
      this.request<DomainTypes[], TransportWebError>({
        path: `/dictionary/types`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  public = {
    /**
     * @description Download file
     *
     * @tags public
     * @name DownloadFile
     * @summary Download file
     * @request GET:/public/files/{id}
     */
    downloadFile: (id: number, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/public/files/${id}`,
        method: "GET",
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Download image
     *
     * @tags public
     * @name DownloadImage
     * @summary Download image
     * @request GET:/public/images/{id}.png
     */
    downloadImage: (id: number, params: RequestParams = {}) =>
      this.request<string, string>({
        path: `/public/images/${id}.png`,
        method: "GET",
        type: ContentType.Json,
        ...params,
      }),
  };
  signin = {
    /**
     * @description Sign in
     *
     * @tags users
     * @name SigninCreate
     * @summary Sign in
     * @request POST:/signin
     */
    signinCreate: (request: TransportUserSignInRequest, params: RequestParams = {}) =>
      this.request<TransportUserProfile, TransportWebError>({
        path: `/signin`,
        method: "POST",
        body: request,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  time = {
    /**
     * @description Return current time im ms.
     *
     * @tags common
     * @name TimeList
     * @summary Get current time
     * @request GET:/time
     */
    timeList: (params: RequestParams = {}) =>
      this.request<GLint64, TransportWebError>({
        path: `/time`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
