import { DomainZvgObject } from '../shared/api/Api';

const googleMapLinkDefiner = (object: DomainZvgObject, type: string) => {
	const formattedAddress =
		type === 'auction'
			? `${object?.auction_meta?.address?.replace(/\s+/g, '+')},${
					object?.auction_meta?.postal_code
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  }+${object?.auction_meta?.city?.replace(/\s+/g, '+')}`
			: `${object?.meta?.address?.replace(/\s+/g, '+')},${
					object?.meta?.postal_code
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  }+${object?.meta?.city?.replace(/\s+/g, '+')}`;
	return `http://maps.google.de/maps?f=q&hl=de&geocode=&q=${formattedAddress}`;
};

export default googleMapLinkDefiner;
