import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';

import closeIcon from '../../../../assets/icons/X.svg';
import { ModalProps } from '../../../../utils/types';
import ObjectCard from '../../../MainPage/components/ObjectCard/ObjectCard';
import './styles.scss';

const ObjectCardsModal: React.FC<ModalProps> = ({ show, handler, objects }) => {
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const settings = {
		dots: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		className: 'objectCardSlide',
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handler}
				className={`objectCardsModal ${isTablet ? 'tablet' : 'mobile'}`}>
				{/* {!isTablet && (
					<Modal.Header>
						<Button className='closeBtn' onClick={handler}>
							<img src={closeIcon} alt='closeIcon' />
						</Button>
					</Modal.Header>
				)} */}
				<Modal.Body>
					{!isTablet && (
						<Button className='closeBtn' onClick={handler}>
							<img src={closeIcon} alt='closeIcon' />
						</Button>
					)}
					{isTablet ? (
						<div className='cardsList'>
							{objects?.map((object) => (
								<ObjectCard object={object} key={object.id} />
							))}
						</div>
					) : (objects?.length as number) > 1 ? (
						<Slider {...settings}>
							{objects?.map((object) => (
								<ObjectCard object={object} key={object.id} />
							))}
						</Slider>
					) : (
						<ObjectCard object={objects?.[0]} />
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ObjectCardsModal;
