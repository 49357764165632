import React from 'react';

import { useTranslation } from 'react-i18next';

import { useApi } from '../../contexts/ApiContext';
import './styles.scss';

const ModalError = () => {
	const { error } = useApi();
	// console.log(error);
	const { t } = useTranslation();

	const translatedError = (error: string) => {
		if (error.includes('Error 1062 (23000):')) {
			return t('modals.requestErrors.existingEmail');
		}
		if (error.includes('Error 1054 (42S22):')) {
			return t('modals.requestErrors.serverError');
		}
		switch (error) {
			case 'user not found':
				return t('modals.requestErrors.userNotFound');
			case `Key: 'UserSignInRequest.Email' Error:Field validation for 'Email' failed on the 'email' tag`:
				return t('modals.requestErrors.incorrectEmail');
			case 'successful':
				return t('modals.requestErrors.successful');
			default:
				break;
		}
	};

	return (
		<>
			{error && error !== 'Unauthorized' && (
				<p
					className={`${
						error === 'successful' ? 'modalSuccess' : 'modalError'
					}`}>
					{translatedError(error)}
				</p>
			)}
		</>
	);
};

export default ModalError;
