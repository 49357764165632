import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
	Api,
	DomainPage,
	DomainZvgObject,
	TransportListRequest,
	TransportWebError,
} from '../shared/api/Api';

const api = new Api({
	withCredentials: true,
	baseURL: `/api/v1`,
});

export const objectsAPI = createApi({
	reducerPath: 'objectsAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `/api/v1`,
	}),
	endpoints: (build) => ({
		fetchFirstPage: build.query<DomainPage, string>({
			async queryFn() {
				try {
					const response = await api.a.zvgObjectList();
					return {
						data: response.data,
					};
				} catch (error: any) {
					return { error: { status: 'CUSTOM_ERROR', error: error.message } };
				}
			},
			providesTags: (result) => ['Objects' as never],
		}),
		fetchDefinedPage: build.query<DomainPage, TransportListRequest>({
			async queryFn(request) {
				try {
					const response = await api.a.zvgObjectCreate(request);
					return {
						data: response.data,
					};
				} catch (error: any) {
					return { error: { status: 'CUSTOM_ERROR', error: error.message } };
				}
			},
			providesTags: (result) => ['Objects' as never],
		}),
		fetchAllObjects: build.query<DomainZvgObject[], string>({
			async queryFn() {
				try {
					const response = await api.a.getA2();
					return {
						data: response.data,
					};
				} catch (error: any) {
					return { error: { status: 'CUSTOM_ERROR', error: error.message } };
				}
			},
			providesTags: (result) => ['Objects' as never],
		}),
		fetchObjectDetail: build.query<DomainZvgObject, string>({
			async queryFn(id) {
				try {
					const response = await api.a.zvgObjectDetail(id);
					return {
						data: response.data,
					};
				} catch (error: any) {
					return { error: { status: 'CUSTOM_ERROR', error: error.message } };
				}
			},
			providesTags: (result) => ['ObjectDetail' as never],
		}),

		// 		setFavouriteBuilding: build.mutation<
		// 			TransportOkResponse | TransportWebError,
		// 			{ id: string; favorite: boolean }
		// 		>({
		// 			async queryFn({ id, favorite }) {
		// 				try {
		// 					const response = await api.a.buildingFavDetail(id, {
		// 						favorite,
		// 					});
		// 					return {
		// 						data: response.data,
		// 					};
		// 				} catch (error: any) {
		// 					return { error: { status: 'CUSTOM_ERROR', error: error.message } };
		// 				}
		// 			},
		// 			// invalidatesTags: ['Buildings' as never],
		// 		}),
	}),
});

export {};
