import React from 'react';

import { Modal } from 'react-bootstrap';
import { ColorRing } from 'react-loader-spinner';

import './styles.scss';

const LoadingModal: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
	return (
		<Modal show={isLoading} className='loadingModal'>
			<Modal.Body>
				<ColorRing
					visible={true}
					height='180'
					width='180'
					ariaLabel='color-ring-loading'
					wrapperStyle={{}}
					wrapperClass='color-ring-wrapper'
					colors={['#00FFD0', '#00FFD0', '#00FFD0', '#00FFD0', '#00FFD0']}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default LoadingModal;
