import React from 'react';

import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { useMediaQuery } from 'react-responsive';
// import { useNavigate } from 'react-router-dom';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
// import { LazyLoadTypes } from 'react-slick';
import { Tooltip } from 'react-tooltip';

import noPhoto from '../../../../assets/abc66fba8658e5f11780d4fd1a7b6d35.png';
// import arrow from '../../../../assets/icons/Arrow-back-outline.svg';
// import files from '../../../../assets/icons/files.svg';
// import notFavourite from '../../../../assets/icons/Heart-outline.svg';
import geo from '../../../../assets/icons/location-pin.svg';
import logo from '../../../../assets/icons/logo_vigiland_1_white 1.svg';
import { useAppSelector } from '../../../../hooks/redux';
import { DomainZvgObject } from '../../../../shared/api/Api';
import googleMapLinkDefiner from '../../../../utils/googleMapLinkDefiner';
import './styles.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const ObjectCard: React.FC<{ object?: DomainZvgObject; isMap?: boolean }> = ({
	object,
	isMap,
}) => {
	const { t } = useTranslation();
	// const isMobile = useMediaQuery({ maxWidth: '767px' });

	const sortedPhotos = object?.images
		? [...object.images].sort((a, b) => {
				// Сортируем по weight
				if (a.weight !== b.weight) {
					return (b.weight as number) - (a.weight as number); // От большего к меньшему
				}
				// Если weight одинаковый, сортируем по id
				return (a.id as number) - (b.id as number); // От меньшего к большему
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  })
		: [];

	// console.log(sortedPhotos);
	const photos = sortedPhotos.length
		? sortedPhotos.map((image) => `/api/v1/public/images/${image.id}`)
		: [];

	const settings = {
		dots: false,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: (photos?.length as number) > 1,
		className: 'objectImgsSlide',
		// lazyLoad: 'ondemand' as LazyLoadTypes,
	};

	/* const typesRef = useRef<HTMLDivElement>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);

	const handleMouseDown = (e: React.MouseEvent) => {
		setIsDragging(true);
		setStartX(e.pageX - (typesRef.current?.offsetLeft || 0));
		setScrollLeft(typesRef.current?.scrollLeft || 0);
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const handleMouseMove = (e: React.MouseEvent) => {
		if (!isDragging) return;
		e.preventDefault();
		const x = e.pageX - (typesRef.current?.offsetLeft || 0);
		const walk = (x - startX) * 1.5; // скорость прокрутки
		typesRef.current!.scrollLeft = scrollLeft - walk;
	}; */

	/* let formattedName = object?.meta?.name;
	if (formattedName && formattedName.length > 75) {
		formattedName = formattedName.slice(0, 75) + '...';
	} */

	const { search, pathname } = useLocation();
	const navigate = useNavigate();

	const { dictionaries } = useAppSelector((state) => state.dictionariesReducer);

	const landName = dictionaries.lands
		?.filter((land) => land.code === object?.meta?.land)
		.map((land) => land.name);

	const address = `${landName}, ${object?.meta?.city}, ${object?.meta?.address}`;
	/* if (formattedAddress && formattedAddress.length > 30) {
		formattedAddress = formattedAddress.slice(0, 30) + '...';
	} */

	let description = object?.meta_texts?.[0] && object.meta_texts[0].description;

	if (description && (description.length as number) >= 85) {
		description = description?.slice(0, 85) + '...';
	}

	const timezoneOffset = localStorage.getItem('timezone');
	const numericOffset = parseInt(timezoneOffset as string, 10) * 60; // Превращаем смещение в минуты

	/* const formattedCreatedAt = dayjs
		.utc(object?.created_at)
		.utcOffset(numericOffset)
		.format('DD.MM.YYYY'); */

	const formattedTerminAt = dayjs
		.utc(object?.termin_at)
		.utcOffset(numericOffset)
		.format('DD.MM.YYYY HH:mm');

	const isTerminated = dayjs().isAfter(
		dayjs.utc(object?.termin_at).utcOffset(numericOffset)
	);

	/* const formattedActualAt = dayjs
		.utc(object?.aktuality_at)
		.utcOffset(numericOffset)
		.format('DD.MM.YYYY'); */

	const formattedPrice = object?.auction_meta?.price
		? object.auction_meta.price.toLocaleString('de-DE') // Немецкая локаль для разделения точками
		: '';

	// const navigate = useNavigate();

	/* const handleEnterObject = () => { 	navigate(`/objects/${object?.id}`);
	 }; */

	return (
		<div className={`objectCard ${isTerminated ? 'terminated' : ''}`}>
			<div className='photosTags'>
				<div className='photos'>
					{photos && photos.length > 0 ? (
						<Slider {...settings}>
							{photos.map((photo, index) => (
								<img src={photo} alt={photo} key={index} /* loading='lazy' */ />
							))}
						</Slider>
					) : (
						<>
							<img src={noPhoto} className='noPhoto' alt='noPhoto' />
							<div className='logo'>
								<img src={logo} alt='Logo' className='logoImg' />
								<span className='logoName'>Vigiland</span>
							</div>
						</>
					)}
					{/* <Button className='favouriteBtn'>
					<img src={notFavourite} alt='favouriteIcon' />
				</Button> */}
					{/* {!isMap && !isMobile && (
					<div className='priceFiles'>
						<p className='price'>Price: € {formattedPrice}</p>
							{!isMap && !isMobile && (
								<Button className='files'>
									<img src={files} alt='filesIcon' />
									<div className='filesCount'>3</div>
								</Button>
							)}
						</div>
				)} */}
				</div>
				<div
					className='types' /*${isDragging && 'grabbing'}` */
					/* ref={typesRef}
					onMouseDown={handleMouseDown}
					onMouseLeave={handleMouseUp}
					onMouseUp={handleMouseUp}
					onMouseMove={handleMouseMove} */
				>
					{/* <p>{t('mainPage.filters.prefixes.types')}:</p> */}
					{object?.meta?.types?.map((type, index) =>
						index < 2 ? (
							<div
								className='type'
								data-tooltip-id={`${
									(object.meta?.types?.length as number) >= 2
										? type.length > 14
											? 'my-tooltip'
											: ''
										: type.length > 43
										? 'my-tooltip'
										: ''
								}`}
								data-tooltip-content={type}
								data-tooltip-place='top'
								key={index}>
								<p>
									{(object.meta?.types?.length as number) >= 2
										? type.length > 14
											? type.slice(0, 14) + '...'
											: type
										: type.length > 43
										? type.slice(0, 43) + '...'
										: type}
								</p>
							</div>
						) : index === 2 ? (
							<div className='type' key={index}>
								<p>{`+${(object?.meta?.types?.length as number) - 2}`}</p>
							</div>
						) : null
					)}
				</div>
				<Tooltip id='my-tooltip' className='typeTooltip' />
			</div>
			<Link
				onClick={() =>
					sessionStorage.setItem('scrollPosition', String(window.scrollY))
				}
				to={`/objects/${object?.id}`}
				state={{ previousLocationPathname: pathname, search: search }}
				style={{
					textDecoration: 'none',
					width: '100%',
					height: '100%',
					minHeight: '245px',
				}}
				className='linkToObject'>
				<div className='info'>
					<div className='mainInfoEnterBtn'>
						<div className='nameLocationId'>
							<div className='name'>{object?.meta?.name}</div>
							<Button
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									sessionStorage.setItem(
										'scrollPosition',
										String(window.scrollY)
									);
									navigate(
										`/on-map/${search ? `${search}&` : '?'}activeMarkerId=${
											object?.id
										}`
									);
								}}
								className='location'>
								<img src={geo} alt='locationIcon' />
								<p>{address}</p>
							</Button>
						</div>
						<div className='id'>
							<h4>ID:</h4>
							<h4>{object?.id}</h4>
						</div>
						{/* <Button className='enterBtn' onClick={handleEnterObject}>
						<img src={arrow} alt='arrowIcon' />
					</Button> */}
					</div>
					<p className='description'>{description}</p>
					<div className='extraInfo'>
						{/* <div className='createDate'>
								<p className='title'>{t('objectPage.titles.createAt')}</p>
								<p className='date'>{formattedCreatedAt}</p>
							</div> */}
						<div className='terminDate'>
							<p className='title'>{t('objectPage.titles.terminAt')}</p>
							<p className='date'>
								{isTerminated && '! '}
								{formattedTerminAt}
							</p>
						</div>
						<p className='price'>
							{formattedPrice}
							<span>,00</span> €
						</p>
						{/* <div className='actualDate'>
								<p className='title'>{t('objectPage.titles.actualAt')}</p>
								<p className='date'>{formattedActualAt}</p>
							</div> */}
					</div>
					{/* {(isMap || isMobile) && (
						<div className='metaInfo'>
							<div className='id'>
								<h4>ID:</h4>
								<h4>{object?.id}</h4>
							</div>
							<p className='price'>€ {formattedPrice}</p>
							<Button className='files'>
								<img src={files} alt='filesIcon' />
								<div className='filesCount'>3</div>
							</Button>
						</div>
					)} */}
				</div>
			</Link>
			{isMap && (
				<a
					href={
						object?.auction_meta?.geo_link ??
						googleMapLinkDefiner(object as DomainZvgObject, 'object')
					}
					target='_blank'
					rel='noreferrer'
					className='googleMapLink'>
					{t('objectCard.onGoogleMap')}
				</a>
			)}
		</div>
	);
};

export default ObjectCard;
