import React, { FC, useState } from 'react';

import { useFormik } from 'formik';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import eyeSlash from '../../../assets/icons/Eye-off-outline.svg';
import eye from '../../../assets/icons/Eye-outline.svg';
import envelope from '../../../assets/icons/Mail-outline.svg';
import person from '../../../assets/icons/Person-outline.svg';
import { useApi } from '../../../contexts/ApiContext';
// import { useModals } from '../../../../contexts/ModalsContext';
import ModalError from '../../../hoc/ModalError/ModalError';
import i18n from '../../../i18n';
import { TransportUserSignInRequest } from '../../../shared/api/Api';
import { ModalProps } from '../../../utils/types';
import './styles.scss';

const { t } = i18n;

const validationSchema: yup.ObjectSchema<TransportUserSignInRequest> = yup
	.object()
	.shape({
		email: yup
			.string()
			.trim()
			.email(t('modals.signIn.errors.email.incorrectFormat'))
			.required(t('modals.signIn.errors.email.required'))
			.max(150, t('modals.signIn.errors.email.maxLength'))
			.min(3, t('modals.signIn.errors.email.minLength')),
		password: yup
			.string()
			.trim()
			.required(t('modals.signIn.errors.password.required'))
			.min(6, t('modals.signIn.errors.password.minLength'))
			.max(100, t('modals.signIn.errors.password.maxLength')),
		remember: yup.boolean().required(),
	});

const SignInModal: FC<ModalProps> = ({ show }) => {
	const { /* setError, */ signIn } = useApi();
	/* const {
		signInShow,
		signInHandler,
		signUpHandler,
		mobileMenuOn,
		mobileMenuHandler,
	} = useModals(); */
	const { t } = useTranslation();

	const formik = useFormik({
		initialValues: {
			email: localStorage.getItem('rememberedEmail') || '',
			password: '',
			remember: false,
		},
		validationSchema,
		onSubmit: async (values: TransportUserSignInRequest) => {
			try {
				await signIn(values);
				formik.values.remember === true
					? localStorage.setItem('rememberedEmail', formik.values.email)
					: localStorage.removeItem('rememberedEmail');
				/* if (mobileMenuOn) {
					mobileMenuHandler();
				}
				signInHandler(); */
			} catch (err: any) {
				// Ошибка уже обработана в signIn, поэтому здесь ничего дополнительно делать не нужно
			}
		},
	});

	const [showPassword, setShowPassword] = useState(false);

	const handleEyeClick = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<Modal
				show={show}
				/* onHide={() => {
					signInHandler();
					setError('');
				}} */
				centered
				className='signInModal'>
				<div className='formCircle'>
					<img src={person} alt='person' />
				</div>
				<Modal.Body>
					<div className='modalHeader'>
						<p className='modal-title'>{t('modals.signIn.title')}</p>
						<p>{t('modals.signIn.description')}</p>
					</div>
					<ModalError />
					<Form onSubmit={formik.handleSubmit} className='signInForm'>
						<div className='input-wrapper'>
							<Form.Control
								name='email'
								placeholder={t('modals.signIn.placeholders.email')}
								className={`custom-form-control ${
									formik.touched.email && formik.errors.email
										? 'is-invalid'
										: ''
								}`}
								required
								value={formik.values.email}
								onChange={formik.handleChange}
							/>
							<img src={envelope} alt='envelope' className='envelope' />
							<Form.Control.Feedback type='invalid' className='d-block'>
								{formik.touched.email && formik.errors.email}
							</Form.Control.Feedback>
						</div>
						<div className='input-wrapper'>
							<Form.Control
								name='password'
								placeholder={t('modals.signIn.placeholders.password')}
								type={showPassword ? 'text' : 'password'}
								className={`custom-form-control ${
									formik.touched.password && formik.errors.password
										? 'is-invalid'
										: ''
								}`}
								required
								value={formik.values.password}
								onChange={formik.handleChange}
							/>
							<img
								src={showPassword ? eye : eyeSlash}
								onClick={handleEyeClick}
								alt='showPassword'
								className='showPassword'
							/>
							<Form.Control.Feedback type='invalid' className='d-block'>
								{formik.touched.password && formik.errors.password}
							</Form.Control.Feedback>
						</div>
						<div className='rememberMeCheckbox'>
							<input
								className='form-check-input'
								type='checkbox'
								id='rememberMeCheck'
								checked={formik.values.remember}
								onChange={(e) =>
									formik.setFieldValue('remember', e.target.checked)
								}
							/>
							<label htmlFor='rememberMeCheck' className='form-check-label'>
								{t('modals.signIn.rememberMeCheck')}
							</label>
						</div>
						<Button type='submit' className='signInButton'>
							{t('modals.signIn.signInBtn')}
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default SignInModal;
