const de = {
	translation: {
		navbar: {
			mainPage: 'Startseite',
			onMap: 'Anzeigen auf der Karte',
			timezones: {
				Azores: 'Azoren',
				London: 'London',
				Paris: 'Paris',
				Berlin: 'Berlin',
				Moscow: 'Moskau',
				AbuDhabi: 'Abu Dhabi',
			},
		},
		toasts: {
			error: {
				noPage: 'Die von Ihnen angeforderte Seite existiert nicht',
				unauthorized: 'Sie müssen sich zuerst anmelden',
			},
		},
		modals: {
			signIn: {
				title: 'In das Konto einloggen',
				description: 'Vigiland Parser',
				placeholders: {
					email: 'Geben Sie Ihre E-Mail ein',
					password: 'Passwort',
				},
				rememberMeCheck: 'Mich erinnern',
				signInBtn: 'Einloggen',
				errors: {
					email: {
						incorrectFormat: 'Die E-Mail muss korrekt sein',
						required: 'E-Mail eingeben',
						maxLength: 'E-Mail ist zu lang',
						minLength: 'E-Mail ist zu kurz',
					},
					password: {
						required: 'Passwort eingeben',
						maxLength: 'Passwort ist zu lang',
						minLength: 'Passwort ist zu kurz',
					},
					confirmPassword: {
						notOneOf: 'Passwörter stimmen nicht überein',
					},
					policyAgreement: {
						notTrue: 'Datenschutzrichtlinie akzeptieren',
					},
				},
			},
			exit: {
				title: 'Möchten Sie sich ausloggen?',
				cancelBtn: 'Abbrechen',
				exitBtn: 'Ausloggen',
			},
		},
		mainPage: {
			mainFilter: {
				searchBtn: 'Suche',
			},
			filters: {
				title: 'Filter nach Objekt',
				mobileMapTitle: 'Filter',
				labels: {
					area: 'Fläche (m²)',
					price: 'Preis (€)',
					showTerminalCheck: 'Abgelaufene anzeigen',
				},
				placeholders: {
					area_from: 'ab',
					price_from: 'ab',
					to: 'bis',
					all: 'Alle',
					noCities: 'Keine verfügbaren Städte',
				},
				prefixes: {
					land: 'Land',
					city: 'Stadt',
					type: 'Typ',
					types: 'Typen',
					terminDates: 'Termin-Daten',
				},
				terminDatesLabels: {
					0: 'Aktueller Monat',
					1: 'Aktueller und nächster Monat',
					2: 'Aktueller und die nächsten 2 Monate',
					3: 'Aktueller und die nächsten 3 Monate',
				},
				sortByLabels: {
					dateFromUp: 'Zuerst neu',
					dateFromDown: 'Zuerst alt',
					priceFromUp: 'Nach Preis absteigend',
					priceFromDown: 'Nach Preis aufsteigend',
					landFromUp: 'Nach Region (A-Z)',
					landFromDown: 'Nach Region (Z-A)',
					cityFromUp: 'Nach Stadt (A-Z)',
					cityFromDown: 'Nach Stadt (Z-A)',
				},
				btns: {
					search: 'Suche',
					clearFilters: 'Filter zurücksetzen',
				},
			},
			objectFrom: 'Von',
			objectTo: 'bis',
			objectOf: 'Anzeige von',
			sortBy: 'Sortieren nach:',
			showOnTheMap: 'Auf der Karte anzeigen',
			toTop: 'Zurück nach oben',
		},
		objectCard: {
			in: 'in',
			onGoogleMap: 'Auf Google Map',
		},
		objectPage: {
			timer: {
				days_one: 'Tag',
				days: 'Tage',
				hours_one: 'Std.',
				hours: 'Std.',
				minutes_one: 'Min.',
				minutes: 'Min.',
				seconds_one: 'Zweite',
				seconds: 'Sek.',
			},
			titles: {
				createAt: 'Erstellungsdatum:',
				terminAt: 'Auktionsdatum:',
				actualAt: 'Letzte Aktualisierung:',
				description: 'Objektbeschreibung',
				price: 'Preis',
				files: 'Zusätzliche Dateien zum Objekt',
				auction: 'Auktionsinformationen',
				terminated: 'AUKTION BEENDET',
			},
			tables: {
				type: 'Typ:',
				area: 'Wohnfläche:',
				meters: 'm²',
				land: 'Land:',
				city: 'Stadt:',
				address: 'Adresse:',
				googleMap: 'Google Map:',
				postalCode: 'Postleitzahl:',
				yearOfConstruction: 'Baujahr:',
				numberOfRooms: 'Zimmeranzahl:',
				numberOfApartments: 'Anzahl der Wohnungen:',
				estimatedValue: 'Geschätzter Wert:',
				quality: 'Qualitätsbewertung:',
				landArea: 'Land vorhanden:',
				yes: 'Ja',
				// landArea: 'Landfläche:',
				level: 'Stockwerk:',
				totalLevel: 'Stockwerke insgesamt:',
				balcony: 'Balkon:',
				heating: 'Heizung:',
				parking: 'Parkplatz:',
				wallMaterial: 'Wandmaterial:',
				date: 'Datum:',
				price: 'Geschätzter Preis:',
				auctionAddress: 'Auktionsadresse:',
				website: 'Webseite:',
				auctionRoom: 'Raum:',
				auctionType: 'Auktionsart:',
			},
			btns: {
				more: 'Mehr anzeigen',
				less: 'Weniger anzeigen',
			},
		},
		onMap: {
			backBtn: 'Zurück zur Liste',
		},
		footer: {
			rights: '© 2024 Vigiland. Alle Rechte vorbehalten.',
		},
	},
};

export default de;
