import React, { useEffect, useState, useMemo, useCallback } from 'react';

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import type { Marker } from '@googlemaps/markerclusterer';
import {
	APIProvider,
	Map,
	useMap,
	AdvancedMarker,
} from '@vis.gl/react-google-maps';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Sidebar } from 'react-pro-sidebar';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import arrow from '../../assets/icons/Arrow-back-outline.svg';
import sidebarArrow from '../../assets/icons/chevron-right-enabled.svg';
import markerIcon from '../../assets/icons/marker.svg';
import activeMarkerIcon from '../../assets/icons/markerActive.svg';
import settingsIcon from '../../assets/icons/sliders-up.svg';
import { useAppDispatch } from '../../hooks/redux';
import useFilter from '../../hooks/useFilter';
import useGetQueryString from '../../hooks/useGetQueryString';
import useSetQueryString from '../../hooks/useSetQueryString';
import { objectsAPI } from '../../services/ObjectsService';
import { DomainZvgObject } from '../../shared/api/Api';
import { objectsSlice } from '../../store/reducers/ObjectsSlice';
import FiltersModal from '../FiltersModal/FiltersModal';
import MainFilters from '../MainPage/components/MainFilters/MainFilters';
import ObjectCard from '../MainPage/components/ObjectCard/ObjectCard';
import ObjectFilters from '../MainPage/components/ObjectFilters/ObjectFilters';
import NavbarComponent from '../NavbarComponent/NavbarComponent';
import ObjectCardsModal from './components/ObjectCardsModal/ObjectCardsModal';
import getAvgCoords from '../../utils/getAvgCoords';
import LoadingModal from '../LoadingModal/LoadingModal';
import './styles.scss';

// Главный компонент приложения, отвечает за загрузку данных и их отображение на карте
const MapPage = () => {
	const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;

	const dispatch = useAppDispatch();
	const { setObjects } = objectsSlice.actions;

	const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
	const handleFiltersModal = () => setShowFiltersModal(!showFiltersModal);

	const [showObjectCardsModal, setObjectCardsModal] = useState<boolean>(false);
	const handleObjectCardsModal = () =>
		setObjectCardsModal(!showObjectCardsModal);
	const handleSetObjectCardsModal = useCallback(
		(data: boolean) => setObjectCardsModal(data),
		[]
	);

	const { data: objects, isLoading } = objectsAPI.useFetchAllObjectsQuery('');

	// Фильтрация объектов на основе выбранных фильтров
	const filteredObjects = useFilter();

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const [center, setCenter] = useState<google.maps.LatLngLiteral | undefined>(
		undefined
	);

	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const activeMarkerId = queryParams.get('activeMarkerId');

	const handleCenter = useCallback(() => {
		const activeObject = objects?.find(
			(object) => object.id === Number(activeMarkerId)
		);

		if (
			activeObject?.meta?.geo?.lat !== undefined &&
			activeObject.meta.geo.lon !== undefined
		) {
			return { lat: activeObject.meta.geo.lat, lng: activeObject.meta.geo.lon };
		}

		// Координаты по умолчанию — средние значения для всех отфильтрованных объектов
		// if (filteredObjects.length > 0) getAvgCoords(filteredObjects);

		return { lat: 52.31, lng: 13.23 }; // координаты по умолчанию, если нет отфильтрованных объектов
	}, [objects, /* filteredObjects,*/ activeMarkerId]);

	useEffect(() => {
		setCenter(handleCenter());
	}, [handleCenter]);

	const [activeObjects, setActiveObjects] = useState<DomainZvgObject[]>([]);
	const handleActiveObjects = useCallback(
		(objects: DomainZvgObject[]) => setActiveObjects(objects),
		[]
	);

	const [showOffcanvas, setShowOffcanvas] = useState<boolean>(false);
	const handleShowOffcanvas = () => setShowOffcanvas(!showOffcanvas);
	const handleSetShowOffcanvas = useCallback(
		(data: boolean) => setShowOffcanvas(data),
		[]
	);
	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setShowOffcanvas(false);
			}
		};

		window.addEventListener('keydown', handleKeyDown);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if (objects) {
			dispatch(setObjects(objects));
		}
	}, [objects, dispatch, setObjects]);

	const navigate = useNavigate();
	const { t } = useTranslation();

	useGetQueryString();
	useSetQueryString();

	// const localObjects = useAppSelector((state) => state.objectsReducer);
	// const filters = useAppSelector((state) => state.filtersReducer);

	return (
		<>
			<LoadingModal isLoading={isLoading} />
			<FiltersModal show={showFiltersModal} handler={handleFiltersModal} />
			<ObjectCardsModal
				show={showObjectCardsModal}
				handler={handleObjectCardsModal}
				objects={activeObjects}
			/>
			<NavbarComponent />
			<main>
				<div className='mapPage'>
					{isDesktop && <MainFilters />}
					{isDesktop && (
						<Sidebar
							collapsed={activeObjects.length === 0 ? true : !showOffcanvas}
							className='sidebar'
							collapsedWidth='32px'
							width='434px'>
							<div className='toggleBtnBlock'>
								<Button
									onClick={handleShowOffcanvas}
									className='toggleCollapseBtn'
									disabled={activeObjects.length === 0}>
									<img
										src={sidebarArrow}
										alt='sidebarArrow'
										className={`${showOffcanvas ? 'active' : ''}`}
									/>
								</Button>
							</div>
							<div className='cardsList'>
								{activeObjects.map((object) => (
									<ObjectCard object={object} key={object.id} />
								))}
							</div>
						</Sidebar>
					)}
					{/* <div className={`collapseBlock ${showOffcanvas ? 'open' : ''}`}>
						<Button onClick={handleShowOffcanvas} className='toggleCollapseBtn'>
							{showOffcanvas ? '<' : '>'}
						</Button>
						<Collapse in={showOffcanvas}>
							<div className='cardsList'>
								{activeObjects.map((object, index) => (
									<ObjectCard {...object} key={index} />
								))}
							</div>
						</Collapse>
					</div> */}
					<div className='filtersMap'>
						{isDesktop ? (
							<>
								<Button className='backBtn' onClick={() => navigate('/')}>
									<img src={arrow} alt='arrowIcon' />
									<p>{t('onMap.backBtn')}</p>
								</Button>
								<div
									className='filtersAccordion accordion'
									id='accordionFilters'>
									<div className='accordion-item'>
										<h2 className='accordion-header'>
											<button
												className='accordion-button'
												type='button'
												data-bs-toggle='collapse'
												data-bs-target='#collapseAccordionFilters'
												aria-expanded='true'
												aria-controls='collapseAccordionFilters'>
												{t('mainPage.filters.title')}
											</button>
										</h2>
										<div
											id='collapseAccordionFilters'
											className='accordion-collapse collapse show'
											data-bs-parent='#accordionFilters'>
											<div className='accordion-body'>
												<ObjectFilters />
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<Button className='openFiltersBtn' onClick={handleFiltersModal}>
								<img src={settingsIcon} alt='filtersIcon' />
								{isTablet && <p>{t('mainPage.filters.title')}</p>}
							</Button>
						)}
					</div>
					<APIProvider apiKey={apiKey}>
						{center && ( // Карта рендерится только после загрузки центра
							<Map
								defaultCenter={center}
								defaultZoom={activeMarkerId ? 11 : 9}
								disableDefaultUI={true}
								mapId='762b080f6c2bc23c'
								className='mapComponent'
								gestureHandling={'greedy'}>
								<ClusteredMarkers
									points={filteredObjects}
									handleActiveObjects={handleActiveObjects}
									handleSetShowOffcanvas={handleSetShowOffcanvas}
									handleSetObjectCardsModal={handleSetObjectCardsModal}
								/>
							</Map>
						)}
					</APIProvider>
				</div>
			</main>
		</>
	);
};

// Компонент, отвечающий за кластеризацию маркеров на карте
const ClusteredMarkers: React.FC<{
	points: DomainZvgObject[];
	handleActiveObjects: (data: DomainZvgObject[]) => void;
	handleSetShowOffcanvas: (data: boolean) => void;
	handleSetObjectCardsModal: (data: boolean) => void;
}> = ({
	points,
	handleActiveObjects,
	handleSetShowOffcanvas,
	handleSetObjectCardsModal,
}) => {
	const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
	// const [activeCluster, setActiveCluster] = useState<string | null>(null);
	//const activeM = useRef<string>('');
	/* const updateActiveCluster = useCallback(
		(clusterId: string) => {
			if (activeCluster !== clusterId) {
				setActiveCluster(clusterId);
			}
		},
		[activeCluster]
	); */

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const [activeMarkerId, setActiveMarkerId] = useState<number | null>(null);
	const [tooltipData, setTooltipData] = useState<DomainZvgObject>();

	const deleteActiveMarker = useCallback(() => {
		setActiveMarkerId(null);
	}, []);

	const map = useMap();

	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const activeMarkerIdFromList = queryParams.get('activeMarkerId');

	useEffect(() => {
		if (!activeMarkerIdFromList) {
			map?.setZoom(7);
			points.length > 0 && map?.panTo(getAvgCoords(points));
		}
	}, [points, map, activeMarkerIdFromList]);

	const clusterer = useMemo(() => {
		if (!map) return null;
		return new MarkerClusterer({
			map,
			renderer: {
				render: ({ count, position }) => {
					const cluster = document.createElement('div');
					const clusterId = `cluster-${position
						.lat()
						.toFixed(6)
						.replace(/\./g, '-')}-${position
						.lng()
						.toFixed(6)
						.replace(/\./g, '-')}`;

					cluster.id = clusterId;
					cluster.className =
						/* ( activeCluster === clusterId ? 'active ' : '' ) + */ // Добавляем класс 'active' если кластер активен
						'cluster ' +
						(count > 30 ? 'clusterLg' : count > 9 ? 'clusterMd' : 'clusterSm');
					cluster.textContent =
						count > 30 ? '30+' : count > 9 ? '9+' : String(count);

					return new google.maps.marker.AdvancedMarkerElement({
						content: cluster,
						position,
					});
				},
			},
			onClusterClick: (event, cluster) => {
				let isDesktop = window.matchMedia('(min-width: 1351px)').matches;

				window.addEventListener('resize', () => {
					isDesktop = window.matchMedia('(min-width: 1351px)').matches;
					// console.log('Is desktop:', isDesktop); // Выводим актуальное значение
				});

				// Получаем id кластера и устанавливаем его как активный
				const clusterId = `cluster-${cluster.position
					.lat()
					.toFixed(6)
					.replace(/\./g, '-')}-${cluster.position
					.lng()
					.toFixed(6)
					.replace(/\./g, '-')}`;
				// activeM.current = clusterId;
				// updateActiveCluster(clusterId);

				// console.log('CLUSTER:', cluster, 'EVENT:', event);

				const clusters = document.querySelectorAll('.cluster');
				clusters.forEach((cluster) => {
					if (cluster.id !== clusterId) {
						cluster.removeAttribute('data-active');
					}
				});

				const activeCluster = document.querySelector(`#${clusterId}`);
				activeCluster?.setAttribute('data-active', '1');

				// Получаем все маркеры внутри кластера
				const clusterMarkers = cluster.markers as any[];
				// Массив объектов DomainZvgObject
				const clusterObjects = clusterMarkers.map((marker) => marker.object);

				deleteActiveMarker();

				handleActiveObjects(clusterObjects);

				isDesktop && handleSetShowOffcanvas(true);

				!isDesktop && handleSetObjectCardsModal(true);
			},
		});
	}, [
		map,
		// activeCluster,
		handleActiveObjects,
		handleSetShowOffcanvas,
		deleteActiveMarker,
		// isDesktop,
		handleSetObjectCardsModal,
		// updateActiveCluster,
	]);

	useEffect(() => {
		if (!clusterer) return;
		clusterer.clearMarkers();
		clusterer.addMarkers(Object.values(markers));
	}, [clusterer, markers]);

	const setMarkerRef = useCallback((marker: Marker | null, key: string) => {
		setMarkers((prev) => {
			if ((marker && prev[key]) || (!marker && !prev[key])) return prev;
			if (marker) return { ...prev, [key]: marker };
			const newMarkers = { ...prev };
			delete newMarkers[key];
			return newMarkers;
		});
	}, []);

	return (
		<>
			{points.map((point) => (
				<CustomMarker
					key={String(point.id)}
					point={point}
					setMarkerRef={setMarkerRef}
					setTooltipData={setTooltipData}
					activeMarkerId={activeMarkerId}
					setActiveMarkerId={setActiveMarkerId}
					handleActiveObjects={handleActiveObjects}
					handleSetObjectCardsModal={handleSetObjectCardsModal}
					handleSetShowOffcanvas={handleSetShowOffcanvas}
				/>
			))}
			{(isDesktop || isTablet) && (
				<Tooltip id='my-tooltip' className='cardTooltip' openOnClick>
					<ObjectCard object={tooltipData} isMap />
				</Tooltip>
			)}
		</>
	);
};

// Компонент для отрисовки каждого отдельного маркера на карте
const CustomMarker: React.FC<{
	point: DomainZvgObject;
	setMarkerRef: (marker: Marker | null, key: string) => void;
	setTooltipData: (data: DomainZvgObject) => void;
	activeMarkerId: number | null;
	setActiveMarkerId: (data: number | null) => void;
	handleActiveObjects: (data: DomainZvgObject[]) => void;
	handleSetObjectCardsModal: (data: boolean) => void;
	handleSetShowOffcanvas: (data: boolean) => void;
}> = ({
	point,
	setMarkerRef,
	setTooltipData,
	activeMarkerId,
	setActiveMarkerId,
	handleActiveObjects,
	handleSetObjectCardsModal,
	handleSetShowOffcanvas,
}) => {
	const ref = useCallback(
		(marker: google.maps.marker.AdvancedMarkerElement | null) => {
			if (marker) {
				// Проверка на наличие маркера перед присвоением свойства
				(marker as any).object = point;
			}
			setMarkerRef(marker, String(point.id));
		},
		[setMarkerRef, point]
	);

	const handleMarkerClick = (point: DomainZvgObject) => {
		if (isDesktop || isTablet) {
			setActiveMarkerId(point.id as number);
			setTooltipData(point);
			const clusters = document.querySelectorAll('.cluster');
			clusters.forEach((cluster) => {
				cluster.removeAttribute('data-active');
			});
			isDesktop && handleSetShowOffcanvas(false);
		} else {
			setActiveMarkerId(point.id as number);
			handleActiveObjects([point]);
			handleSetObjectCardsModal(true);
			const clusters = document.querySelectorAll('.cluster');
			clusters.forEach((cluster) => {
				cluster.removeAttribute('data-active');
			});
		}
	};

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const { search } = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(search);
		const activeMarkerId = queryParams.get('activeMarkerId');
		activeMarkerId && setActiveMarkerId(Number(activeMarkerId));
	}, [search, setActiveMarkerId]);

	return (
		<AdvancedMarker
			position={{
				lat: point.meta?.geo?.lat as number,
				lng: point.meta?.geo?.lon as number,
			}}
			ref={ref}
			onClick={() => handleMarkerClick(point)}
			// className={activeMarkerId === point.id ? 'activeMarker' : 'marker'}
		>
			<img
				src={activeMarkerId === point.id ? activeMarkerIcon : markerIcon}
				alt='mapMarker'
				data-tooltip-id='my-tooltip'
				data-tooltip-place='bottom'
			/>
		</AdvancedMarker>
	);
};

export default MapPage;
