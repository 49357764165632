import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
// import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from './redux';
import { DomainZvgObject } from '../shared/api/Api';
import { pageSlice } from '../store/reducers/PageSlice';

const useFilter = (): DomainZvgObject[] => {
	// const navigate = useNavigate();
	// const { search, pathname } = useLocation();
	const localObjects = useAppSelector((state) => state.objectsReducer);
	const filters = useAppSelector((state) => state.filtersReducer);
	/*  const { page, total, limit } = useAppSelector(
		(state) => state.pageReducer
	); */
	const dispatch = useAppDispatch();
	const { setTotal, setPage } = pageSlice.actions;
	const [filteredObjects, setFilterObject] = useState<DomainZvgObject[]>([]);

	useEffect(() => {
		// Проверка, если массив объектов пустой

		// Фильтрация объектов по полю land
		const filteredObjects = localObjects.filter((object) => {
			// По умолчанию фильтрация совпадает
			let matchesLands = true;
			let matchesCities = true;
			let matchesTypes = true;
			let matchesAreaFrom = true;
			let matchesAreaTo = true;
			let matchesPriceFrom = true;
			let matchesPriceTo = true;
			let matchesTerminDates = true;
			let matchesActive = true;

			// Если есть фильтры по land, то проверяем соответствие
			if (filters.land && filters.land.length > 0) {
				matchesLands = filters.land.includes(object.meta?.land as string);
			}

			if (filters.city && filters.city.length > 0) {
				matchesCities = filters.city.includes(object.meta?.city as string);
			}

			if (filters.type && filters.type.length > 0 && object.meta?.types) {
				matchesTypes = object.meta.types.some((type) =>
					filters.type?.includes(type)
				);
			}

			if (filters.area_from && filters.area_from > 0) {
				matchesAreaFrom = (object.meta?.area as number) >= filters.area_from;
			}

			if (filters.area_to && filters.area_to > 0) {
				matchesAreaTo = (object.meta?.area as number) <= filters.area_to;
			}

			if (filters.price_from && filters.price_from > 0) {
				matchesPriceFrom =
					(object.auction_meta?.price as number) >= filters.price_from;
			}

			if (filters.price_to && filters.price_to > 0) {
				matchesPriceTo =
					(object.auction_meta?.price as number) <= filters.price_to;
			}

			if (filters.terminDates && filters.terminDates.length > 0) {
				const currentMonth = dayjs().month(); // Текущий месяц
				const terminMonth = dayjs(object.termin_at).month(); // Месяц из termin_at

				matchesTerminDates = filters.terminDates.some((filter) => {
					const monthOffset = parseInt(filter); // Преобразование строки "+X" в число
					return terminMonth <= currentMonth + monthOffset;
				});
			}

			if (!filters.un_active) {
				const timezoneOffset = localStorage.getItem('timezone');
				const numericOffset = parseInt(timezoneOffset as string, 10) * 60; // Превращаем смещение в минуты

				matchesActive = !dayjs().isAfter(
					dayjs.utc(object?.termin_at).utcOffset(numericOffset)
				);
			} else {
				matchesActive = true; // Если filters.un_active включен, все объекты активны
			}

			// Возвращаем объект, если совпадает с фильтром
			return (
				matchesLands &&
				matchesCities &&
				matchesTypes &&
				matchesAreaFrom &&
				matchesAreaTo &&
				matchesPriceFrom &&
				matchesPriceTo &&
				matchesTerminDates &&
				matchesActive
			);
		});

		// Пагинация отфильтрованных объектов
		setFilterObject(filteredObjects);
		dispatch(setPage(0));
		dispatch(setTotal(filteredObjects.length));
		// console.log(filteredObjects.length);
	}, [localObjects, filters, dispatch, setPage, setTotal]);

	return filteredObjects;
};

export default useFilter;
