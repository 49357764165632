import React from 'react';

import { Button, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import closeIcon from '../../assets/icons/X.svg';
import { ModalProps } from '../../utils/types';
import ObjectFilters from '../MainPage/components/ObjectFilters/ObjectFilters';
import './styles.scss';

const FiltersModal: React.FC<ModalProps> = ({ show, handler }) => {
	const { pathname } = useLocation();

	return (
		<>
			<Modal show={show} onHide={handler} className='filtersModal'>
				<Modal.Body>
					<Button className='closeBtn' onClick={handler}>
						<img src={closeIcon} alt='closeIcon' />
					</Button>
					<ObjectFilters mobileMap={pathname.includes('/on-map')} />
				</Modal.Body>
			</Modal>
		</>
	);
};

export default FiltersModal;
