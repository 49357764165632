import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useApi } from '../../../contexts/ApiContext';
// import { useModals } from '../../../../contexts/ModalsContext';
import { ModalProps } from '../../../utils/types';
import './styles.scss';

const ExitModal: React.FC<ModalProps> = ({ show, handler }) => {
	// const { exitShow, exitHandler } = useModals();
	const { signOut } = useApi();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const exitAccountHandler = () => {
		signOut();
		navigate('/sign-in');
	};

	return (
		<>
			<Modal show={show} onHide={handler} centered className='exitModal'>
				{/* <div className='formCircle'>
					<i className='bi bi-file-earmark-richtext'></i>
				</div>*/}
				<Modal.Body>
					<div className='modalHeader'>
						<p className='modal-title'>{t('modals.exit.title')}</p>
					</div>
					<div className='formBtns'>
						<Button className='closeButton' onClick={handler}>
							{t('modals.exit.cancelBtn')}
						</Button>
						<Button
							type='submit'
							className='exitButton'
							onClick={exitAccountHandler}>
							{t('modals.exit.exitBtn')}
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default ExitModal;
