import i18n from '../../i18n';

const { t } = i18n;

export const terminDatesOptions = [
	{ value: '0', label: t('mainPage.filters.terminDatesLabels.0') },
	{ value: '+1', label: t('mainPage.filters.terminDatesLabels.1') },
	{ value: '+2', label: t('mainPage.filters.terminDatesLabels.2') },
	{ value: '+3', label: t('mainPage.filters.terminDatesLabels.3') },
];

export const sortByOptions = [
	{ value: 'dateFromUp', label: t('mainPage.filters.sortByLabels.dateFromUp') },
	{
		value: 'dateFromDown',
		label: t('mainPage.filters.sortByLabels.dateFromDown'),
	},
	{
		value: 'priceFromUp',
		label: t('mainPage.filters.sortByLabels.priceFromUp'),
	},
	{
		value: 'priceFromDown',
		label: t('mainPage.filters.sortByLabels.priceFromDown'),
	},
	{ value: 'landFromUp', label: t('mainPage.filters.sortByLabels.landFromUp') },
	{
		value: 'landFromDown',
		label: t('mainPage.filters.sortByLabels.landFromDown'),
	},
	{ value: 'cityFromUp', label: t('mainPage.filters.sortByLabels.cityFromUp') },
	{
		value: 'cityFromDown',
		label: t('mainPage.filters.sortByLabels.cityFromDown'),
	},
];
