import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DomainZvgObject } from '../../shared/api/Api';

const initialState: DomainZvgObject[] = [];

export const objectsSlice = createSlice({
	name: 'objects',
	initialState,
	reducers: {
		setObjects(_state, action: PayloadAction<DomainZvgObject[]>) {
			return action.payload;
		},
	},
});

export default objectsSlice.reducer;
