import React from 'react';

import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps';
// import axios from 'axios';

import activeMarkerIcon from '../../../../assets/icons/markerActive.svg';

const GoogleMapSmall: React.FC<{
	address: { lat: number | undefined; lng: number | undefined };
}> = ({ address }) => {
	const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
	// const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json`;

	// Функция для получения координат по адресу
	/* useEffect(() => {
		const fetchCoordinates = async (address: string) => {
			try {
				const response = await axios.get(geocodingApiUrl, {
					params: {
						address,
						key: apiKey,
					},
				});
				const location = response.data.results[0].geometry.location;
				setCoords({ lat: location.lat, lng: location.lng });
			} catch (error) {
				console.error('Error fetching geocode', error);
			}
		};

		if (address) {
			fetchCoordinates(address);
		}
	}, [address, apiKey, geocodingApiUrl]); */

	return (
		<div>
			{address.lat && address.lng ? (
				<APIProvider apiKey={apiKey}>
					<Map
						style={{ width: '100%', height: '230px' }}
						defaultCenter={{ lat: address.lat, lng: address.lng }}
						defaultZoom={15}
						gestureHandling={'greedy'}
						disableDefaultUI={true}
						mapId='762b080f6c2bc23c'>
						<AdvancedMarker position={{ lat: address.lat, lng: address.lng }}>
							<img
								src={activeMarkerIcon}
								alt='mapMarker'
								className='activeMarker'
							/>
						</AdvancedMarker>
					</Map>
				</APIProvider>
			) : (
				<p>Loading map...</p>
			)}
		</div>
	);
};

export default GoogleMapSmall;
