const ru = {
	translation: {
		navbar: {
			mainPage: 'Главная',
			onMap: 'Объявления на карте',
			timezones: {
				Azores: 'Азорские острова',
				London: 'Лондон',
				Paris: 'Париж',
				Berlin: 'Берлин',
				Moscow: 'Москва',
				AbuDhabi: 'Абу-Даби',
			},
		},
		toasts: {
			error: {
				noPage: 'Указанной Вами страницы не существует',
				unauthorized: 'Сначала необходимо авторизоваться',
			},
		},
		modals: {
			signIn: {
				title: 'Войти в аккаунт',
				description: 'Vigiland Parser',
				placeholders: {
					email: 'Введите ваш email',
					password: 'Пароль',
				},
				rememberMeCheck: 'Запомнить меня',
				signInBtn: 'Войти',
				errors: {
					email: {
						incorrectFormat: 'Почта должна быть корректной',
						required: 'Введите почту',
						maxLength: 'Почта слишком длинная',
						minLength: 'Почта слишком короткая',
					},
					password: {
						required: 'Введите пароль',
						maxLength: 'Пароль слишком длинный',
						minLength: 'Пароль слишком короткий',
					},
					confirmPassword: {
						notOneOf: 'Пароли не совпадают',
					},
					policyAgreement: {
						notTrue: 'Примите политику конфиденицальности',
					},
				},
			},
			exit: {
				title: 'Хотите выйти из аккаунта?',
				cancelBtn: 'Отменить',
				exitBtn: 'Выйти',
			},
		},
		mainPage: {
			mainFilter: {
				searchBtn: 'Поиск',
			},
			filters: {
				title: 'Фильтры по объекту',
				mobileMapTitle: 'Фильтры',
				labels: {
					area: 'Площадь (м²)',
					price: 'Цена (€)',
					showTerminalCheck: 'Показать прошедшие',
				},
				placeholders: {
					area_from: 'от',
					price_from: 'от',
					to: 'до',
					all: 'Все',
					noCities: 'Нет доступных городов',
				},
				prefixes: {
					land: 'Регион',
					city: 'Город',
					type: 'Тип',
					types: 'Типы',
					terminDates: 'Даты аукциона',
				},
				terminDatesLabels: {
					0: 'Текущий месяц',
					1: 'Текущий и следующий месяц',
					2: 'Текущий и 2 следующих месяца',
					3: 'Текущий и 3 следующих месяца',
				},
				sortByLabels: {
					dateFromUp: 'Сначала новые',
					dateFromDown: 'Сначала старые',
					priceFromUp: 'По уменьшению цены',
					priceFromDown: 'По увелечению цены',
					landFromUp: 'По региону (A-Z)',
					landFromDown: 'По региону (Z-A)',
					cityFromUp: 'По городу (A-Z)',
					cityFromDown: 'По городу (Z-A)',
				},
				btns: {
					search: 'Поиск',
					clearFilters: 'Сбросить фильтры',
				},
			},
			objectFrom: 'С',
			objectTo: 'по',
			objectOf: 'объявление из',
			sortBy: 'Сортировать по:',
			showOnTheMap: 'Показать на карте',
			toTop: 'Вернуться наверх',
		},
		objectCard: {
			in: 'в',
			onGoogleMap: 'На Google Карте',
		},
		objectPage: {
			timer: {
				days_one: 'день',
				days_few: 'дня',
				days_many: 'дней',
				hours_one: 'час',
				hours_few: 'часа',
				hours_many: 'часов',
				minutes_one: 'минута',
				minutes_few: 'минуты',
				minutes_many: 'минут',
				seconds_one: 'секунда',
				seconds_few: 'секунды',
				seconds_many: 'секунд',
			},
			titles: {
				createAt: 'Дата создания:',
				terminAt: 'Дата аукциона:',
				actualAt: 'Дата актуализации:',
				description: 'Описание объекта',
				price: 'Цена',
				files: 'Дополнительные файлы по объекту',
				auction: 'Информация об аукционе',
				terminated: 'АУКЦИОН ЗАВЕРШЕН',
			},
			tables: {
				type: 'Тип:',
				area: 'Жилая площадь:',
				meters: 'м²',
				land: 'Регион:',
				city: 'Город:',
				address: 'Адрес:',
				googleMap: 'Google Карта:',
				postalCode: 'Индекс:',
				yearOfConstruction: 'Год постройки:',
				numberOfRooms: 'Количество комнат:',
				numberOfApartments: 'Количество апартаментов:',
				estimatedValue: 'Ориентировочная стоимость:',
				quality: 'Рейтинг качества:',
				landArea: 'Наличие участка:',
				yes: 'Да',
				// landArea: 'Площадь участка:',
				level: 'Этаж:',
				totalLevel: 'Всего этажей:',
				balcony: 'Балкон:',
				heating: 'Отопление:',
				parking: 'Парковка:',
				wallMaterial: 'Материал стен:',
				date: 'Дата:',
				price: 'Оценочная стоимость:',
				auctionAddress: 'Адрес проведения:',
				website: 'Веб-сайт:',
				auctionRoom: 'Комната:',
				auctionType: 'Тип аукциона:',
			},
			btns: {
				more: 'Показать',
				less: 'Скрыть',
			},
		},
		onMap: {
			backBtn: 'Назад к списку',
		},
		footer: {
			rights: '© 2024 Vigiland. Все права защищены.',
		},
	},
};

export default ru;
