import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DomainZvgObject } from '../../shared/api/Api';

const initialState: DomainZvgObject = {};

export const activeMarkerSlice = createSlice({
	name: 'activeMarker',
	initialState,
	reducers: {
		setActiveMarker(state, action: PayloadAction<DomainZvgObject>) {
			Object.assign(state, action.payload);
		},
	},
});

export default activeMarkerSlice.reducer;
