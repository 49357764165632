import React, { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { Tooltip } from 'react-tooltip';

import logo from '../..//assets/icons/logo_vigiland_1_white 1.svg';
import noPhoto from '../../assets/abc66fba8658e5f11780d4fd1a7b6d35.png';
// import notFavourite from '../../assets/icons/Heart-outline.svg';
// import geo from '../../assets/icons/location-pin.svg';
import { useApi } from '../../contexts/ApiContext';
import { objectsAPI } from '../../services/ObjectsService';
import { DomainZvgObject } from '../../shared/api/Api';
import { fetchLands } from '../../store/reducers/ActionCreators';
import Footer from '../Footer/Footer';
import NavbarComponent from '../NavbarComponent/NavbarComponent';
import FileBtn from './components/FileBtn/FileBtn';
import GoogleMapSmall from './components/GoogleMapSmall/GoogleMapSmall';
import arrow from '../../assets/icons/Arrow-back-outline.svg';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import changeLanguage from '../../utils/changeLanguage';
import googleMapLinkDefiner from '../../utils/googleMapLinkDefiner';
import LoadingModal from '../LoadingModal/LoadingModal';
import './styles.scss';
import Timer from './components/Timer/Timer';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const ObjectPage = () => {
	const isIpadAir = useMediaQuery({ maxWidth: 820, minWidth: 769 });
	const xxs = useMediaQuery({ maxWidth: 950, minWidth: 821 });
	const isIpadPro = useMediaQuery({ minWidth: 951, maxWidth: 1024 });
	const xs = useMediaQuery({ maxWidth: 1220, minWidth: 1025 });
	const sm = useMediaQuery({ maxWidth: 1380, minWidth: 1221 });
	const md = useMediaQuery({ minWidth: 1381, maxWidth: 1419 });
	const lg = useMediaQuery({ minWidth: 1420, maxWidth: 1889 });
	const xl = useMediaQuery({ minWidth: 1890 });
	const xxl = useMediaQuery({ minWidth: 2378 });

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	const { t } = useTranslation();

	const navigate = useNavigate();

	const location = useLocation();
	const search = location.state.search;
	console.log(search);

	const handleGoBack = (objectId: string) => {
		const queryParams = new URLSearchParams(search);
		queryParams.set('activeMarkerId', objectId);

		if (location.state.previousLocationPathname.includes('/on-map')) {
			navigate({
				pathname: '/on-map/',
				search: queryParams.toString(),
			});
		} else {
			navigate(-1); // Стандартный переход на предыдущую страницу
		}
	};

	const { objectId } = useParams();
	const { data: objects, isLoading } = objectsAPI.useFetchAllObjectsQuery('');
	const object = objects?.find((object) => object.id === Number(objectId));
	// console.log(object);

	useEffect(() => {
		object?.meta_texts?.[0] &&
			object.meta_texts[0].lang &&
			changeLanguage(object.meta_texts[0].lang);
	}, [object?.meta_texts]);

	const sortedPhotos = object?.images
		? [...object.images].sort((a, b) => {
				// Сортируем по weight
				if (a.weight !== b.weight) {
					return (b.weight as number) - (a.weight as number); // От большего к меньшему
				}
				// Если weight одинаковый, сортируем по id
				return (a.id as number) - (b.id as number); // От меньшего к большему
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  })
		: [];
	const photos = sortedPhotos.length
		? sortedPhotos.map((image) => `/api/v1/public/images/${image.id}`)
		: [];

	const mainPhotoSliderSettings = {
		dots: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		afterChange: (index: number) => {
			const photos = document.querySelectorAll<HTMLDivElement>('.etcPhoto');
			if (photos.length === 0) {
				return;
			}
			photos.forEach((photo) => (photo.style.border = 'none'));
			photos[index].style.border = '3px solid #00FFD0';
		},
		onInit: () => {
			const photos = document.querySelectorAll<HTMLDivElement>('.etcPhoto');
			if (photos.length === 0) {
				return;
			}
			photos[0].style.border = '3px solid #00FFD0';
		},
	};

	const etcPhotosSliderSettings = {
		dots: false,
		arrows: false,
		speed: 300,
		slidesToShow: xxl
			? 2.89
			: xl
			? 2.89
			: lg
			? 2.895
			: md
			? 2.65
			: sm
			? 2.65
			: xs
			? 2.7
			: isTablet && isIpadPro
			? 2.42
			: isIpadPro
			? 2.275
			: isIpadAir
			? 1.87
			: xxs
			? 1.95
			: 1.76,
		infinite: false,
		swipeToSlide: true,
	};

	const [isSwiping, setIsSwiping] = useState(false);
	const mainPhotoSliderRef = useRef<Slider>(null);
	const etcPhotoSliderRef = useRef<Slider>(null);

	// Обработчик для начала свайпа
	const handleMouseDown = () => {
		setIsSwiping(false);
	};

	// Обработчик для отслеживания движения мыши (свайпа)
	const handleMouseMove = () => {
		setIsSwiping(true);
	};

	// Обработчик завершения свайпа/клика
	const handleMouseUp = (
		e: React.MouseEvent<HTMLDivElement>,
		index: number
	) => {
		if (!isSwiping) {
			mainPhotoSliderRef.current?.slickGoTo(index);
		}
	};

	// Обрезка описания
	const description =
		object?.meta_texts?.[0] && object.meta_texts[0].description;
	const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
	const maxDescriptionLength = 170;

	const handleToggleDescription = () => {
		setIsDescriptionExpanded(!isDescriptionExpanded);
	};

	const slicedDescription =
		description && description.length > maxDescriptionLength
			? description.slice(0, maxDescriptionLength) + '...'
			: description;

	const timezoneOffset = localStorage.getItem('timezone');
	const numericOffset = parseInt(timezoneOffset as string, 10) * 60; // Превращаем смещение в минуты

	const formattedCreatedAt = dayjs
		.utc(object?.created_at)
		.utcOffset(numericOffset)
		.format('DD.MM.YYYY');

	const formattedActualAt = dayjs
		.utc(object?.aktuality_at)
		.utcOffset(numericOffset)
		.format('DD.MM.YYYY');

	const formattedTerminAt = dayjs
		.utc(object?.termin_at)
		.utcOffset(numericOffset)
		.format('DD.MM.YYYY HH:mm');

	const isTerminated = dayjs().isAfter(
		dayjs.utc(object?.termin_at).utcOffset(numericOffset)
	);

	const formattedPrice = object?.auction_meta?.price
		? object.auction_meta.price.toLocaleString('de-DE') // Немецкая локаль для разделения точками
		: '';

	// const dataStrings = object?.meta
	// 	? Object.entries(object.meta as DomainZvgObjectMeta)
	// 	: [];

	/* const extractAddressFromGoogleMapsLink = (
		url: string | undefined
	): string | undefined => {
		try {
			if (!url) {
				return;
			}
			const urlObj = new URL(url);
			const addressParam = urlObj.searchParams.get('q');
			return addressParam ? decodeURIComponent(addressParam) : undefined;
		} catch (error) {
			console.error('Invalid URL', error);
			return undefined;
		}
	};

	// Пример использования
	const address = extractAddressFromGoogleMapsLink(
		object?.auction_meta?.geo_link
	); */

	const dispatch = useAppDispatch();
	const { getLands } = useApi(); // Достаем getLands из контекста
	const { dictionaries } = useAppSelector((state) => state.dictionariesReducer);

	useEffect(() => {
		dictionaries.lands?.length === 0 && dispatch(fetchLands(getLands));
		// console.log(dictionaries);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, getLands]);

	const landName = dictionaries.lands
		?.filter((land) => land.code === object?.meta?.land)
		.map((land) => land.name);

	return (
		<>
			<LoadingModal isLoading={isLoading} />
			<NavbarComponent />
			<main>
				{isDesktop || isTablet ? (
					<div className='objectPage serviceBlock'>
						<div className='nameMeta'>
							<h5 className='name'>{object?.meta?.name}</h5>
							<div className='meta'>
								{/* <div className='location'>
									<img src={geo} alt='locationIcon' />
									<a>
										{object?.meta?.city}, {object?.meta?.address}
									</a>
								</div> */}
								<a className='siteId'>{object?.site_id}</a>
								<div className='createDate'>
									<p>{t('objectPage.titles.createAt')}</p>
									<p>{formattedCreatedAt}</p>
								</div>
								<div className='actualDate'>
									<p>{t('objectPage.titles.actualAt')}</p>
									<p>{formattedActualAt}</p>
								</div>
								{/* <div
									className={`terminDate ${isTerminated ? 'terminated' : ''}`}>
									<p>{t('objectPage.titles.terminAt')}</p>
									<p>
										{' '}
										{isTerminated && '! '}
										{formattedTerminAt}
									</p>
								</div> */}
							</div>
							{isDesktop && (
								<Button
									className='backBtn'
									onClick={() => handleGoBack(String(object?.id))}>
									<img src={arrow} alt='arrowIcon' />
								</Button>
							)}
						</div>
						{!isDesktop && (
							<div className='photoSlider'>
								{(photos?.length as number) > 0 ? (
									photos?.map((photo, index) => (
										<img
											alt={photo}
											src={photo}
											className='picture'
											key={index}
										/>
									))
								) : (
									<>
										<img src={noPhoto} className='noPhoto' alt='noPhoto' />
										<div className='logo'>
											<img src={logo} alt='Logo' className='logoImg' />
											<span className='logoName'>Vigiland</span>
										</div>
									</>
								)}
								{/* <Button className='favouriteBtn'>
									<img src={notFavourite} alt='favouriteIcon' />
								</Button> */}
							</div>
						)}
						<div className='objectInnerCard'>
							<div className='leftColumn'>
								{isDesktop && (
									<div className='photos'>
										<div className='mainPhoto'>
											{(photos?.length as number) > 0 ? (
												<Slider
													{...mainPhotoSliderSettings}
													ref={mainPhotoSliderRef}>
													{photos?.map((photo, index) => (
														<img src={photo} key={index} alt={photo} />
													))}
												</Slider>
											) : (
												<>
													<img
														src={noPhoto}
														className='noPhoto'
														alt='noPhoto'
													/>
													<div className='logo'>
														<img src={logo} alt='Logo' className='logoImg' />
														<span className='logoName'>Vigiland</span>
													</div>
												</>
											)}
										</div>
										{(photos.length as number) > 1 && (
											<div className='etcPhotos'>
												<Slider
													{...etcPhotosSliderSettings}
													ref={etcPhotoSliderRef}>
													{photos.map((photo, index) => (
														<img
															src={photo}
															key={index}
															onMouseDown={handleMouseDown}
															onMouseMove={handleMouseMove}
															onMouseUp={(e) => handleMouseUp(e, index)}
															alt={photo}
															className='etcPhoto'
														/>
													))}
												</Slider>
											</div>
										)}
										{(photos.length as number) > 3 && (
											<div className='fade'></div>
										)}
										{/* <Button className='favouriteBtn'>
											<img src={notFavourite} alt='favouriteIcon' />
										</Button> */}
									</div>
								)}
								<div className='descriptionPrice'>
									<div className='description'>
										<h6 className='title'>
											{t('objectPage.titles.description')}
										</h6>
										<p className='content'>
											{isDescriptionExpanded ? description : slicedDescription}
										</p>
										{description &&
											description.length > maxDescriptionLength && (
												<Button
													onClick={handleToggleDescription}
													className='moreBtn'>
													{isDescriptionExpanded
														? t('objectPage.btns.less')
														: t('objectPage.btns.more')}
												</Button>
											)}
									</div>
									<div className='price'>
										<h6 className='title'>{t('objectPage.titles.price')}</h6>
										<p className='content'>
											{formattedPrice}
											<span>,00</span> €
										</p>
									</div>
								</div>
								<div className='files'>
									<h6 className='title'>{t('objectPage.titles.files')}</h6>
									<div className='content'>
										{object?.files?.map((file) => (
											<FileBtn {...file} key={file.id} />
										))}
									</div>
								</div>
								{isTablet && (
									<div className='map'>
										<GoogleMapSmall
											address={{
												lat: object?.meta?.geo?.lat,
												lng: object?.meta?.geo?.lon,
											}}
										/>
									</div>
								)}
							</div>
							<div className='rightColumn'>
								<table className='table'>
									<tbody>
										{(object?.meta?.types?.length as number) > 0 && (
											<tr>
												<th scope='row'>{t('objectPage.tables.type')}</th>
												<td>
													<div className='types'>
														{object?.meta?.types?.map((type, index) => (
															<div
																className='type'
																data-tooltip-id={`${
																	type.length > 35 ? 'my-tooltip' : ''
																}`}
																data-tooltip-content={type}
																data-tooltip-place='top'
																key={index}>
																<p>
																	{type.length > 35
																		? type.slice(0, 35) + '...'
																		: type}
																</p>
															</div>
														))}
														<Tooltip id='my-tooltip' className='typeTooltip' />
													</div>
												</td>
											</tr>
										)}
										{(object?.meta?.area as number) > 0 && (
											<tr>
												<th scope='row'>{t('objectPage.tables.area')}</th>
												<td>
													{object?.meta?.area} {t('objectPage.tables.meters')}
												</td>
											</tr>
										)}
										{object?.meta?.land && object.meta.land !== '' && (
											<tr>
												<th scope='row'>{t('objectPage.tables.land')}</th>
												<td>{landName}</td>
											</tr>
										)}
										{object?.meta?.city && object.meta.city !== '' && (
											<tr>
												<th scope='row'>{t('objectPage.tables.city')}</th>
												<td>{object?.meta?.city}</td>
											</tr>
										)}
										{(landName ||
											object?.meta?.city ||
											object?.meta?.address ||
											object?.meta?.postal_code) && (
											<tr>
												<th scope='row'>{t('objectPage.tables.address')}</th>
												<td>
													<Link
														to={`/on-map/?activeMarkerId=${object?.id}`}
														className='address'>
														{landName},
														{object?.meta?.city && object.meta.city.trim()},{' '}
														{object?.meta?.address &&
															object.meta.address.trim()}
														,{' '}
														{object?.meta?.postal_code &&
															object.meta.postal_code.trim()}
													</Link>
												</td>
											</tr>
										)}
										{/* <tr>
											<th scope='row'>{t('objectPage.tables.postalCode')}</th>
											<td>{object?.meta?.postal_code}</td>
										</tr> */}
										<tr>
											<th scope='row'>{t('objectPage.tables.googleMap')}</th>
											<td>
												<Button
													href={
														object?.auction_meta?.geo_link ??
														googleMapLinkDefiner(
															object as DomainZvgObject,
															'object'
														)
													}
													className='googleMapBtn'
													target='_blank'
													rel='noreferrer'>
													<img src={arrow} alt='arrowIcon' />
												</Button>
											</td>
										</tr>
										{(object?.meta?.year_of_construction as number) > 0 && (
											<tr>
												<th scope='row'>
													{t('objectPage.tables.yearOfConstruction')}
												</th>
												<td>{object?.meta?.year_of_construction}</td>
											</tr>
										)}
										{(object?.meta?.number_of_rooms as number) > 0 && (
											<tr>
												<th scope='row'>
													{t('objectPage.tables.numberOfRooms')}
												</th>
												<td>{object?.meta?.number_of_rooms}</td>
											</tr>
										)}
										{(object?.meta?.number_of_apartments as number) > 0 && (
											<tr>
												<th scope='row'>
													{t('objectPage.tables.numberOfApartments')}
												</th>
												<td>{object?.meta?.number_of_apartments}</td>
											</tr>
										)}
										{(object?.meta?.estimated_value as number) > 0 && (
											<tr>
												<th scope='row'>
													{t('objectPage.tables.estimatedValue')}
												</th>
												<td>
													<p className='estimatedValue'>
														{object?.meta?.estimated_value?.toLocaleString(
															'de-DE'
														)}
														<span>,00</span> €
													</p>
												</td>
											</tr>
										)}
										{(object?.meta?.quality as number) > 0 && (
											<tr>
												<th scope='row'>{t('objectPage.tables.quality')}</th>
												<td>{object?.meta?.quality}</td>
											</tr>
										)}
										{/* {object?.meta?.land && object.meta.land.trim() > '' && (
											<tr>
												<th scope='row'>{t('objectPage.tables.land')}</th>
												<td>{object?.meta?.land?.trim()}</td>
											</tr>
										)} */}
										{(object?.meta?.land_area as number) > 0 && (
											<tr>
												<th scope='row'>{t('objectPage.tables.landArea')}</th>
												<td>
													{t('objectPage.tables.yes')},{' '}
													{object?.meta?.land_area}{' '}
													{t('objectPage.tables.meters')}
												</td>
											</tr>
										)}
										{(object?.meta?.level as number) > 0 && (
											<tr>
												<th scope='row'>{t('objectPage.tables.level')}</th>
												<td>{object?.meta?.level}</td>
											</tr>
										)}
										{(object?.meta?.total_level as number) > 0 && (
											<tr>
												<th scope='row'>{t('objectPage.tables.totalLevel')}</th>
												<td>{object?.meta?.total_level}</td>
											</tr>
										)}
										{object?.meta_texts?.[0] &&
											(object.meta_texts[0].balcony as string) > '' &&
											object.meta_texts[0].balcony !== 'null' && (
												<tr>
													<th scope='row'>{t('objectPage.tables.balcony')}</th>
													<td>{object.meta_texts[0].balcony?.trim()}</td>
												</tr>
											)}
										{object?.meta_texts?.[0] &&
											(object.meta_texts[0].heating as string) > '' &&
											object.meta_texts[0].heating !== 'null' && (
												<tr>
													<th scope='row'>{t('objectPage.tables.heating')}</th>
													<td>{object.meta_texts[0].heating?.trim()}</td>
												</tr>
											)}
										{object?.meta_texts?.[0] &&
											(object.meta_texts[0].parking as string) > '' &&
											object.meta_texts[0].parking !== 'null' && (
												<tr>
													<th scope='row'>{t('objectPage.tables.parking')}</th>
													<td>{object.meta_texts[0].parking?.trim()}</td>
												</tr>
											)}
										{object?.meta_texts?.[0] &&
											(object.meta_texts[0].wall_material as string) > '' &&
											object.meta_texts[0].wall_material !== 'null' && (
												<tr>
													<th scope='row'>
														{t('objectPage.tables.wallMaterial')}
													</th>
													<td>{object.meta_texts[0].wall_material?.trim()}</td>
												</tr>
											)}
									</tbody>
								</table>
								{!isTablet && (
									<div className='map'>
										<GoogleMapSmall
											address={{
												lat: object?.meta?.geo?.lat,
												lng: object?.meta?.geo?.lon,
											}}
										/>
									</div>
								)}
								<div
									className='auctionInfo accordion'
									id='accordionAuctionInfo'>
									<div className='accordion-item'>
										<h2 className='accordion-header'>
											<button
												className='accordion-button'
												type='button'
												data-bs-toggle='collapse'
												data-bs-target='#collapseAuctionInfo'
												aria-expanded='true'
												aria-controls='collapseAuctionInfo'>
												{t('objectPage.titles.auction')}
											</button>
										</h2>
										<div
											id='collapseAuctionInfo'
											className='accordion-collapse collapse show'
											data-bs-parent='#accordionAuctionInfo'>
											<div className='accordion-body'>
												<table className='table'>
													<tbody>
														{object?.termin_at && (
															<tr>
																<th scope='row'>
																	{t('objectPage.tables.date')}
																</th>
																<td>
																	<p
																		className={`${
																			isTerminated ? 'terminated' : ''
																		}`}>
																		{isTerminated && '! '}
																		{formattedTerminAt}
																	</p>
																</td>
															</tr>
														)}
														{(object?.auction_meta?.price as number) > 0 && (
															<tr>
																<th scope='row'>
																	{t('objectPage.tables.price')}
																</th>
																<td>
																	<p className='auctionPrice'>
																		{formattedPrice}
																		<span>,00</span> €
																	</p>
																</td>
															</tr>
														)}
														{(object?.auction_meta?.city ||
															object?.auction_meta?.address ||
															object?.auction_meta?.postal_code) && (
															<tr>
																<th scope='row'>
																	{t('objectPage.tables.auctionAddress')}
																</th>
																<td>
																	<a
																		href={googleMapLinkDefiner(
																			object as DomainZvgObject,
																			'auction'
																		)}
																		target='_blank'
																		rel='noreferrer'
																		className='googleMapLink'>
																		{object?.auction_meta?.city &&
																			object.auction_meta.city.trim()}
																		,{' '}
																		{object?.auction_meta?.address &&
																			object.auction_meta.address.trim()}
																		,{' '}
																		{object?.auction_meta?.postal_code &&
																			object.auction_meta.postal_code.trim()}
																	</a>
																</td>
															</tr>
														)}
														{object?.auction_meta?.gericht_link && (
															<tr>
																<th scope='row'>
																	{t('objectPage.tables.website')}
																</th>
																<td>
																	<Button
																		href={object.auction_meta.gericht_link}
																		className='gerlichtBtn'
																		target='_blank'
																		rel='noreferrer'>
																		<img src={arrow} alt='arrowIcon' />
																	</Button>
																</td>
															</tr>
														)}
														{object?.auction_meta?.room &&
															object.auction_meta.room.trim() !== '' && (
																<tr>
																	<th scope='row'>
																		{t('objectPage.tables.auctionRoom')}
																	</th>
																	<td>{object?.auction_meta?.room?.trim()}</td>
																</tr>
															)}
														{object?.auction_meta?.type &&
															object.auction_meta.type.trim() !== '' && (
																<tr>
																	<th scope='row'>
																		{t('objectPage.tables.auctionType')}
																	</th>
																	<td>{object?.auction_meta?.type?.trim()}</td>
																</tr>
															)}
													</tbody>
												</table>
												{!isTerminated ? (
													<Timer
														isTerminated={isTerminated}
														termin_at={object?.termin_at as string}
														numericOffset={numericOffset}
													/>
												) : (
													<h5>{t('objectPage.titles.terminated')}</h5>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className='objectPage serviceBlock'>
						<div className='photoSlider'>
							{(photos?.length as number) > 0 ? (
								photos?.map((photo, index) => (
									<img
										alt={photo}
										src={photo}
										className='picture'
										key={index}
									/>
								))
							) : (
								<>
									<img src={noPhoto} className='noPhoto' alt='noPhoto' />
									<div className='logo'>
										<img src={logo} alt='Logo' className='logoImg' />
										<span className='logoName'>Vigiland</span>
									</div>
								</>
							)}
							{/* <Button className='favouriteBtn'>
								<img src={notFavourite} alt='favouriteIcon' />
							</Button> */}
						</div>
						<div className='nameMeta'>
							<h5 className='name'>{object?.meta?.name}</h5>
							<div className='meta'>
								<div className='locationSiteId'>
									{/* <div className='location'>
										<img src={geo} alt='locationIcon' />
										<a>
											{object?.meta?.city}, {object?.meta?.address}
										</a>
									</div> */}
									<a className='siteId'>{object?.site_id}</a>
								</div>
								<div className='dates'>
									<div className='createDate'>
										<p>{t('objectPage.titles.createAt')}</p>
										<p>{formattedCreatedAt}</p>
									</div>
									<div className='actualDate'>
										<p>{t('objectPage.titles.actualAt')}</p>
										<p>{formattedActualAt}</p>
									</div>
								</div>
							</div>
							{/* <Button className='backBtn' onClick={handleGoBack}>
								<img src={arrow} alt='arrowIcon' />
							</Button> */}
						</div>
						<div className='price'>
							<h6 className='title'>{t('objectPage.titles.price')}</h6>
							<p className='content'>
								{formattedPrice}
								<span>,00</span> €
							</p>
						</div>
						<table className='table'>
							<tbody>
								{(object?.meta?.types?.length as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.type')}</th>
										<td>
											<div className='types'>
												{object?.meta?.types?.map((type, index) => (
													<div
														className='type'
														data-tooltip-id={`${
															type.length > 31 ? 'my-tooltip' : ''
														}`}
														data-tooltip-content={type}
														data-tooltip-place='top'
														key={index}>
														<p>
															{type.length > 31
																? type.slice(0, 31) + '...'
																: type}
														</p>
													</div>
												))}
												<Tooltip id='my-tooltip' className='typeTooltip' />
											</div>
										</td>
									</tr>
								)}
								{(object?.meta?.area as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.area')}</th>
										<td>
											{object?.meta?.area} {t('objectPage.tables.meters')}
										</td>
									</tr>
								)}
								{object?.meta?.land && object.meta.land !== '' && (
									<tr>
										<th scope='row'>{t('objectPage.tables.land')}</th>
										<td>{landName}</td>
									</tr>
								)}
								{object?.meta?.city && object.meta.city !== '' && (
									<tr>
										<th scope='row'>{t('objectPage.tables.city')}</th>
										<td>{object?.meta?.city}</td>
									</tr>
								)}
								{(landName ||
									object?.meta?.city ||
									object?.meta?.address ||
									object?.meta?.postal_code) && (
									<tr>
										<th scope='row'>{t('objectPage.tables.address')}</th>
										<td>
											<Link
												to={`/on-map/?activeMarkerId=${object?.id}`}
												className='address'>
												{landName},
												{object?.meta?.city && object.meta.city.trim()},{' '}
												{object?.meta?.address && object.meta.address.trim()},{' '}
												{object?.meta?.postal_code &&
													object.meta.postal_code.trim()}
											</Link>
										</td>
									</tr>
								)}
								{/* <tr>
											<th scope='row'>{t('objectPage.tables.postalCode')}</th>
											<td>{object?.meta?.postal_code}</td>
										</tr> */}
								<tr>
									<th scope='row'>{t('objectPage.tables.googleMap')}</th>
									<td>
										<Button
											href={
												object?.auction_meta?.geo_link ??
												googleMapLinkDefiner(
													object as DomainZvgObject,
													'object'
												)
											}
											className='googleMapBtn'
											target='_blank'
											rel='noreferrer'>
											<img src={arrow} alt='arrowIcon' />
										</Button>
									</td>
								</tr>
								{(object?.meta?.year_of_construction as number) > 0 && (
									<tr>
										<th scope='row'>
											{t('objectPage.tables.yearOfConstruction')}
										</th>
										<td>{object?.meta?.year_of_construction}</td>
									</tr>
								)}
								{(object?.meta?.number_of_rooms as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.numberOfRooms')}</th>
										<td>{object?.meta?.number_of_rooms}</td>
									</tr>
								)}
								{(object?.meta?.number_of_apartments as number) > 0 && (
									<tr>
										<th scope='row'>
											{t('objectPage.tables.numberOfApartments')}
										</th>
										<td>{object?.meta?.number_of_apartments}</td>
									</tr>
								)}
								{(object?.meta?.estimated_value as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.estimatedValue')}</th>
										<td>
											<p className='estimatedValue'>
												{object?.meta?.estimated_value?.toLocaleString('de-DE')}
												<span>,00</span> €
											</p>
										</td>
									</tr>
								)}
								{(object?.meta?.quality as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.quality')}</th>
										<td>{object?.meta?.quality}</td>
									</tr>
								)}
								{/* {object?.meta?.land && object.meta.land.trim() > '' && (
											<tr>
												<th scope='row'>{t('objectPage.tables.land')}</th>
												<td>{object?.meta?.land?.trim()}</td>
											</tr>
										)} */}
								{(object?.meta?.land_area as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.landArea')}</th>
										<td>
											{t('objectPage.tables.yes')}, {object?.meta?.land_area}{' '}
											{t('objectPage.tables.meters')}
										</td>
									</tr>
								)}
								{(object?.meta?.level as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.level')}</th>
										<td>{object?.meta?.level}</td>
									</tr>
								)}
								{(object?.meta?.total_level as number) > 0 && (
									<tr>
										<th scope='row'>{t('objectPage.tables.totalLevel')}</th>
										<td>{object?.meta?.total_level}</td>
									</tr>
								)}
								{object?.meta_texts?.[0] &&
									(object.meta_texts[0].balcony as string) > '' &&
									object.meta_texts[0].balcony !== 'null' && (
										<tr>
											<th scope='row'>{t('objectPage.tables.balcony')}</th>
											<td>{object.meta_texts[0].balcony?.trim()}</td>
										</tr>
									)}
								{object?.meta_texts?.[0] &&
									(object.meta_texts[0].heating as string) > '' &&
									object.meta_texts[0].heating !== 'null' && (
										<tr>
											<th scope='row'>{t('objectPage.tables.heating')}</th>
											<td>{object.meta_texts[0].heating?.trim()}</td>
										</tr>
									)}
								{object?.meta_texts?.[0] &&
									(object.meta_texts[0].parking as string) > '' &&
									object.meta_texts[0].parking !== 'null' && (
										<tr>
											<th scope='row'>{t('objectPage.tables.parking')}</th>
											<td>{object.meta_texts[0].parking?.trim()}</td>
										</tr>
									)}
								{object?.meta_texts?.[0] &&
									(object.meta_texts[0].wall_material as string) > '' &&
									object.meta_texts[0].wall_material !== 'null' && (
										<tr>
											<th scope='row'>{t('objectPage.tables.wallMaterial')}</th>
											<td>{object.meta_texts[0].wall_material?.trim()}</td>
										</tr>
									)}
							</tbody>
						</table>
						<div className='description'>
							<h6 className='title'>{t('objectPage.titles.description')}</h6>
							<p className='content'>
								{isDescriptionExpanded ? description : slicedDescription}
							</p>
							{description && description.length > maxDescriptionLength && (
								<Button onClick={handleToggleDescription} className='moreBtn'>
									{isDescriptionExpanded
										? t('objectPage.btns.less')
										: t('objectPage.btns.more')}
								</Button>
							)}
						</div>
						<div className='files'>
							<h6 className='title'>{t('objectPage.titles.files')}</h6>
							<div className='content'>
								{object?.files?.map((file) => (
									<FileBtn {...file} key={file.id} />
								))}
							</div>
						</div>
						<div className='map'>
							<GoogleMapSmall
								address={{
									lat: object?.meta?.geo?.lat,
									lng: object?.meta?.geo?.lon,
								}}
							/>
						</div>
						<div className='auctionInfo accordion' id='accordionAuctionInfo'>
							<div className='accordion-item'>
								<h2 className='accordion-header'>
									<button
										className='accordion-button'
										type='button'
										data-bs-toggle='collapse'
										data-bs-target='#collapseAuctionInfo'
										aria-expanded='true'
										aria-controls='collapseAuctionInfo'>
										{t('objectPage.titles.auction')}
									</button>
								</h2>
								<div
									id='collapseAuctionInfo'
									className='accordion-collapse collapse show'
									data-bs-parent='#accordionAuctionInfo'>
									<div className='accordion-body'>
										<table className='table'>
											<tbody>
												{object?.termin_at && (
													<tr>
														<th scope='row'>{t('objectPage.tables.date')}</th>
														<td>
															<p
																className={`${
																	isTerminated ? 'terminated' : ''
																}`}>
																{isTerminated && '! '}
																{formattedTerminAt}
															</p>
														</td>
													</tr>
												)}
												{(object?.auction_meta?.price as number) > 0 && (
													<tr>
														<th scope='row'>{t('objectPage.tables.price')}</th>
														<td>
															<p className='auctionPrice'>
																{formattedPrice}
																<span>,00</span> €
															</p>
														</td>
													</tr>
												)}
												{(object?.auction_meta?.city ||
													object?.auction_meta?.address ||
													object?.auction_meta?.postal_code) && (
													<tr>
														<th scope='row'>
															{t('objectPage.tables.auctionAddress')}
														</th>
														<td>
															<a
																href={googleMapLinkDefiner(
																	object as DomainZvgObject,
																	'auction'
																)}
																target='_blank'
																rel='noreferrer'
																className='googleMapLink'>
																{object?.auction_meta?.city &&
																	object.auction_meta.city.trim()}
																,{' '}
																{object?.auction_meta?.address &&
																	object.auction_meta.address.trim()}
																,{' '}
																{object?.auction_meta?.postal_code &&
																	object.auction_meta.postal_code.trim()}
															</a>
														</td>
													</tr>
												)}
												{object?.auction_meta?.gericht_link && (
													<tr>
														<th scope='row'>
															{t('objectPage.tables.website')}
														</th>
														<td>
															<Button
																href={object.auction_meta.gericht_link}
																className='gerlichtBtn'
																target='_blank'
																rel='noreferrer'>
																<img src={arrow} alt='arrowIcon' />
															</Button>
														</td>
													</tr>
												)}
												{object?.auction_meta?.room &&
													object.auction_meta.room.trim() !== '' && (
														<tr>
															<th scope='row'>
																{t('objectPage.tables.auctionRoom')}
															</th>
															<td>{object?.auction_meta?.room?.trim()}</td>
														</tr>
													)}
												{object?.auction_meta?.type &&
													object.auction_meta.type.trim() !== '' && (
														<tr>
															<th scope='row'>
																{t('objectPage.tables.auctionType')}
															</th>
															<td>{object?.auction_meta?.type?.trim()}</td>
														</tr>
													)}
											</tbody>
										</table>
										{!isTerminated ? (
											<Timer
												isTerminated={isTerminated}
												termin_at={object?.termin_at as string}
												numericOffset={numericOffset}
											/>
										) : (
											<h5>{t('objectPage.titles.terminated')}</h5>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</main>
			<Footer />
		</>
	);
};

export default ObjectPage;
