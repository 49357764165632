import { DomainZvgObject } from '../shared/api/Api';

const getAvgCoords = (objects: DomainZvgObject[]) => {
	const avgLat =
		objects.reduce((sum, obj) => sum + (obj?.meta?.geo?.lat || 0), 0) /
		objects.length;
	const avgLon =
		objects.reduce((sum, obj) => sum + (obj?.meta?.geo?.lon || 0), 0) /
		objects.length;

	return { lat: avgLat, lng: avgLon };
};

export default getAvgCoords;
