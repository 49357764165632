import React, { useEffect, useState } from 'react';

import { Button, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';

import Pagination from './components/Pagination/Pagination';
// import stringsIcon from '../../assets/icons/align-justify.svg';
// import squaresIcon from '../../assets/icons/grid.svg';
// import favouriteIcon from '../../assets/icons/Heart-outline.svg';
// import { objects } from './components/ObjectCard/mocks';
// import i18n from '../../i18n';
import { objectsAPI } from '../../services/ObjectsService';
import Footer from '../Footer/Footer';
import NavbarComponent from '../NavbarComponent/NavbarComponent';
import MainFilters from './components/MainFilters/MainFilters';
import MapButton from './components/MapButton/MapButton';
import ObjectCard from './components/ObjectCard/ObjectCard';
import ObjectFilters from './components/ObjectFilters/ObjectFilters';
import { sortByOptions } from './consts';
import settingsIcon from '../../assets/icons/sliders-up.svg';
// import { useAppSelector } from '../../hooks/redux';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useFilterAndSortAndPaginate from '../../hooks/useFilterAndSortAndPaginate';
import useGetQueryString from '../../hooks/useGetQueryString';
import useSetQueryString from '../../hooks/useSetQueryString';
import { DomainZvgObject } from '../../shared/api/Api';
import { objectsSlice } from '../../store/reducers/ObjectsSlice';
// import { pageSlice } from '../../store/reducers/PageSlice';
import FiltersModal from '../FiltersModal/FiltersModal';
import './styles.scss';
import LoadingModal from '../LoadingModal/LoadingModal';

const MainPage = () => {
	const dispatch = useAppDispatch();
	const { setObjects } = objectsSlice.actions;
	// const { setTotal } = pageSlice.actions;
	const { page, total, limit } = useAppSelector((state) => state.pageReducer);
	const { t } = useTranslation();
	// const sidebarRef = useRef<HTMLDivElement>(null);
	const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
	const handleFiltersModal = () => setShowFiltersModal(!showFiltersModal);
	const [sortBySelected, setSorBy] = useState<{ value: string; label: string }>(
		sortByOptions[0]
	);
	/* const currentPage = useAppSelector((state) => state.pageReducer);
	const filters = useAppSelector((state) => state.filtersReducer); */

	/* const request: TransportListRequest = {
		page: currentPage,
		filter: filters,
		language: i18n.language,
	}; */

	const isDesktop = useMediaQuery({ query: '(min-width: 1351px)' });
	// const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1350 });

	/* const { data: domainPage } = objectsAPI.useFetchDefinedPageQuery(request);
	const { limit = 0, page = 0, total = 0, list = [] } = domainPage || {}; */

	const { data: objects, isLoading } = objectsAPI.useFetchAllObjectsQuery('');
	const localObjects = useFilterAndSortAndPaginate(sortBySelected.value);

	useEffect(() => {
		if (objects) {
			dispatch(setObjects(objects));
		}
	}, [objects, dispatch, setObjects]);

	const [showToTop, setShowToTop] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			// Высота половины экрана
			const halfWindowHeight = window.innerHeight / 2;
			// Проверяем, что прокрутка больше половины экрана
			setShowToTop(window.scrollY > halfWindowHeight);
		};

		window.addEventListener('scroll', handleScroll);

		// Удаление слушателя при размонтировании
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		const savedPosition = sessionStorage.getItem('scrollPosition');
		if (savedPosition) {
			setTimeout(() => {
				window.scrollTo({
					top: parseInt(savedPosition, 10),
					// behavior: 'smooth'
				});
				sessionStorage.removeItem('scrollPosition');
			}, 0);
		}
	}, []);

	const handleScrollToTop = () =>
		window.scrollTo({ top: 0, behavior: 'smooth' });

	/* useEffect(() => {
		const handleScroll = () => {
			if (sidebarRef.current) {
				const scrollY = window.scrollY;
				const sidebarTop = sidebarRef.current.offsetTop;

				// Если прокрутка больше, чем позиция sidebar, фиксируем его
				if (scrollY > sidebarTop) {
					sidebarRef.current.style.transform = `translateY(${
						scrollY - sidebarTop - 195
					}px)`;
				} else {
					sidebarRef.current.style.transform = 'translateY(0)';
				}
			}
		};

		window.addEventListener('scroll', handleScroll);

		// Удаляем слушатель при размонтировании компонента
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []); */

	// console.log(objects);

	useGetQueryString();
	useSetQueryString();

	return (
		<>
			<LoadingModal isLoading={isLoading} />
			<FiltersModal show={showFiltersModal} handler={handleFiltersModal} />
			<NavbarComponent />
			<main style={{ backgroundColor: '#fafafa' }}>
				<div className='mainPage serviceBlock'>
					<MainFilters />
					<div className='mainContent'>
						<Navbar className='filtersMap sticky-top vertical'>
							{isDesktop ? (
								<ObjectFilters />
							) : (
								<Button className='openFiltersBtn' onClick={handleFiltersModal}>
									<img src={settingsIcon} alt='filtersIcon' />
									<p>{t('mainPage.filters.title')}</p>
								</Button>
							)}
							<MapButton />
							{isDesktop && showToTop && (
								<Button className='toTop' onClick={handleScrollToTop}>
									↑ {t('mainPage.toTop')}
								</Button>
							)}
						</Navbar>
						<div className='objectsControl'>
							<div className='totalNumber'>
								<p>
									{t('mainPage.objectFrom')}{' '}
									{page * limit + 1 > total ? total : page * limit + 1}{' '}
									{t('mainPage.objectTo')}{' '}
									{page * limit + limit > total ? total : page * limit + limit}{' '}
									{t('mainPage.objectOf')} {total}
								</p>
							</div>
							<div className='upperControlsMeta'>
								<Pagination />
								<div className='sortFavsLayout'>
									{/* <Button className='favourites'>
										<img src={favouriteIcon} alt='favouritesIcon' />
										<div className='favouritesCount'>3</div>
									</Button> */}
									<Select
										// components={{ SingleValue: CustomSingleValuePrefix }}
										options={sortByOptions}
										//@ts-ignore
										// prefix={`Сортировать по:`}
										placeholder={t('mainPage.sortBy')}
										value={sortBySelected}
										className='sortBySelector'
										classNamePrefix='sortBySelector'
										onChange={(option) => option && setSorBy(option)}
									/>
									{/* {(isDesktop || isTablet) && (
										<div className='layoutBtns'>
											<Button className='stringsBtn'>
												<img src={stringsIcon} alt='stringsIcon' />
											</Button>
											<Button className='squaresBtn'>
												<img src={squaresIcon} alt='squaresIcon' />
											</Button>
										</div>
									)} */}
								</div>
							</div>
							<div className='objects'>
								{(localObjects[page] ?? []).map((object: DomainZvgObject) => (
									<ObjectCard object={object} key={object.id} />
								))}
							</div>
							<div className='lowerControls'>
								<p>
									{t('mainPage.objectFrom')}{' '}
									{page * limit + 1 > total ? total : page * limit + 1}{' '}
									{t('mainPage.objectTo')}{' '}
									{page * limit + limit > total ? total : page * limit + limit}{' '}
									{t('mainPage.objectOf')} {total}
								</p>
								<Pagination />
							</div>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
};

export default MainPage;
