import React from 'react';

import { Button } from 'react-bootstrap';

import file from '../../../../assets/icons/files.svg';
import { DomainZvgFile } from '../../../../shared/api/Api';
import './styles.scss';

const FileBtn: React.FC<DomainZvgFile> = ({ name, size, id }) => {
	const formattedSize = (size: number) => {
		const megabytes = size / (1024 * 1024);
		return megabytes.toFixed(2); // округление до 2 знаков после запятой
	};

	return (
		<Button
			className='fileBtn'
			href={`https://vigiland-web.axgrid.com/api/v1/public/files/${id}.pdf`}
			target='_blank'
			rel='noreferrer'>
			<img src={file} alt='filesIcon' />
			<p>{`${name}.pdf`}</p>
			<div className='fileWeight'>{formattedSize(size as number)} Mb</div>
		</Button>
	);
};

export default FileBtn;
