import React, { useEffect, useState } from 'react';

import { Button, Dropdown, Modal, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import exit from '../../../../assets/icons/arrow-right-from-bracket.svg';
import arrow from '../../../../assets/icons/Chevron-down-outline.svg';
import closeIcon from '../../../../assets/icons/X.svg';
import changeLanguage from '../../../../utils/changeLanguage';
import { ModalProps } from '../../../../utils/types';
import ExitModal from '../../../Modals/ExitModal/ExitModal';
import { timezones } from '../../consts';
import './styles.scss';

const MobileMenu: React.FC<ModalProps> = ({ show, handler }) => {
	const [showExitModal, setExitModal] = useState<boolean>(false);
	const handleExitModal = () => setExitModal(!showExitModal);
	const [localTimezone, setLocalTimezone] = useState<string | null>(
		localStorage.getItem('timezone')
	);

	if (!localTimezone) {
		const timezoneOffset = new Date().getTimezoneOffset();
		const utcOffset = -timezoneOffset / 60;

		const filteredTimezones = timezones.filter(
			(timezone) => timezone.utc === `${utcOffset >= 0 ? '+' : ''}${utcOffset}`
		);
		// console.log(filteredTimezones);
		if (filteredTimezones.length <= 0) {
			localStorage.setItem('timezone', `+2`);
		}
	}

	const { search } = useLocation();

	const handleTimezone = (utc: string) => {
		localStorage.setItem('timezone', utc);
		setLocalTimezone(utc);
	};

	const { t, i18n } = useTranslation();

	const [activeLink, setActiveLink] = useState('');

	const location = useLocation();
	const pathname = location.pathname;

	const activeLinkDefiner = (url: string) => {
		if (url.includes('/on-map')) {
			setActiveLink('map');
		}
	};

	useEffect(() => {
		activeLinkDefiner(pathname);
	}, [pathname]);

	return (
		<>
			<ExitModal show={showExitModal} handler={handleExitModal} />
			<div className='mobileMenuBG'></div>
			<Modal show={show} onHide={handler} className='mobileMenu'>
				<Modal.Body>
					<Button className='closeBtn' onClick={handler}>
						<img src={closeIcon} alt='closeIcon' />
					</Button>
					<Nav className='links'>
						<Nav.Link
							as={Link}
							to={`/${search}`}
							className={`${activeLink === '' && 'active'}`}>
							{t('navbar.mainPage')}
						</Nav.Link>
						<Nav.Link
							as={Link}
							to={`/on-map${search}`}
							className={`${activeLink === 'map' && 'active'}`}>
							{t('navbar.onMap')}
						</Nav.Link>
						<Dropdown>
							<Dropdown.Toggle
								id='timezonesDropdown'
								className='timezonesDropdown'>
								{timezones
									.filter((timezone) => timezone.utc === localTimezone)
									.map(
										(timezone) =>
											`UTC ${timezone.utc}, ${t(
												`navbar.timezones.${timezone.city}`
											)}`
									)}
								<img
									src={arrow}
									alt='dropdownArrow'
									className='dropdownArrow'
								/>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{timezones
									.filter((timezone) => timezone.utc !== localTimezone)
									.map((timezone) => (
										<Dropdown.Item
											key={timezone.city}
											className='timezoneBtn'
											onClick={() => handleTimezone(timezone.utc)}>
											{'UTC '}
											{timezone.utc}, {t(`navbar.timezones.${timezone.city}`)}
										</Dropdown.Item>
									))}
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown>
							<Dropdown.Toggle id='lngDropdown' className='lngDropdown'>
								{i18n.language.toUpperCase()}
								<img
									src={arrow}
									alt='dropdownArrow'
									className='dropdownArrow'
								/>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{i18n.options.supportedLngs &&
									i18n.options.supportedLngs
										.filter((lng) => lng !== i18n.language && lng !== 'cimode')
										.map((lng) => (
											<Dropdown.Item
												key={lng}
												className='lngBtn'
												onClick={() => changeLanguage(lng)}>
												{lng.toUpperCase()}
											</Dropdown.Item>
										))}
							</Dropdown.Menu>
						</Dropdown>
						<Button className='exitBtn' onClick={handleExitModal}>
							<img src={exit} alt='exitBtn' />
						</Button>
					</Nav>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default MobileMenu;
