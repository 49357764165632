import React, { useEffect } from 'react';

import { FormikProps, useFormik } from 'formik';
// import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
// import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import * as yup from 'yup';

import { useApi } from '../../../../contexts/ApiContext';
import {
	CustomMultiValueOption,
	CustomValueContainer,
} from '../../../../hoc/SelectCustoms/SelectCustoms';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import useFilter from '../../../../hooks/useFilter';
import { DomainZvgFilter } from '../../../../shared/api/Api';
import {
	fetchCities,
	fetchLands,
} from '../../../../store/reducers/ActionCreators';
/* import {
	/* handleSetCities,
	handleSetLands, 
} from '../../../../utils/filtersHandlers'; */
import { filtersSlice } from '../../../../store/reducers/FiltersSlice';
import { optionsCounter } from '../../../../utils/optionsCounter';
import './styles.scss';

const MainFilters: React.FC = () => {
	const { t } = useTranslation();
	const filters = useAppSelector((state) => state.filtersReducer);
	// const localObjects = useAppSelector((state) => state.objectsReducer);
	const dispatch = useAppDispatch();
	const { dictionaries } = useAppSelector((state) => state.dictionariesReducer);
	const { getLands, getCities } = useApi(); // Достаем getLands из контекста
	const { setLands, setCities } = filtersSlice.actions;

	/* const landOptions = dictionaries.lands?.map((land) => ({
		value: land,
		label: land.name,
	})); */

	const filteredObjects = useFilter();

	useEffect(() => {
		dictionaries.lands?.length === 0 && dispatch(fetchLands(getLands));
		dictionaries.cities?.length === 0 && dispatch(fetchCities(getCities));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, getLands]);

	const validationSchema: yup.ObjectSchema<
		Pick<DomainZvgFilter, 'land' | 'city'>
	> = yup.object().shape({
		land: yup.array().of(yup.string().defined()), // Строки без `undefined` в массиве
		city: yup.array().of(yup.string().defined()), // Строки без `undefined` в массиве
	});

	const formik: FormikProps<DomainZvgFilter> = useFormik<DomainZvgFilter>({
		initialValues: {
			land: filters.land ?? [],
			city: filters.city ?? [],
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			//
		},
	});

	useEffect(() => {
		// Получаем текущие выбранные земли
		const selectedLands = formik.values.land;

		// Фильтруем города, которые относятся к текущим выбранным землям
		const filteredCities = dictionaries.cities?.filter((city) =>
			selectedLands?.includes(city.land as string)
		);

		// Обновляем значение в formik для 'city', удаляя несоответствующие города
		const validCities = formik.values.city?.filter((cityName) =>
			filteredCities?.some((city) => city.name === cityName)
		);

		// Если города изменились, обновляем значение в formik
		if (formik.values.city?.length !== validCities?.length) {
			formik.setFieldValue('city', validCities);
			dispatch(setCities(validCities as string[]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.land, formik, dispatch, setCities]);

	const filteredCities = (() => {
		const cities = dictionaries.cities;
		const selectedLands = formik.values.land;

		if (Array.isArray(cities) && cities.length > 0) {
			if (selectedLands && selectedLands.length > 0) {
				// Фильтруем и сортируем, если земли выбраны
				return cities
					.filter((city) => selectedLands.includes(city.land as string))
					.slice() // shallow copy
					.sort((a, b) => (a.name as string).localeCompare(b.name as string));
			}
			// Если земли не выбраны, сортируем все города
			return cities
				.slice()
				.sort((a, b) => (a.name as string).localeCompare(b.name as string));
		}
		// Если городов нет, возвращаем пустой массив
		return [];
	})();

	// const { search } = useLocation();
	// const navigate = useNavigate();

	return (
		// Оборачиваем компоненты в form с использованием formik.handleSubmit
		<form onSubmit={formik.handleSubmit} className='mainFilters'>
			<Select
				isMulti
				hideSelectedOptions={false}
				components={{
					ValueContainer: CustomValueContainer,
					Option: CustomMultiValueOption,
				}}
				options={
					dictionaries.lands
						?.map((land) => {
							const count = optionsCounter(filteredObjects, 'land', land.code);
							return {
								value: land,
								label: count > 0 ? `${land.name} (${count})` : land.name,
								count, // добавляем count для сортировки
							};
						})
						.sort((a, b) => {
							// Если count отличается, то элементы с count > 0 идут перед count === 0
							if (a.count === 0 && b.count !== 0) return 1;
							if (a.count !== 0 && b.count === 0) return -1;
							// Если count равен, сортируем по label в алфавитном порядке
							return (a.label as string).localeCompare(b.label as string);
						})
						.map(({ value, label }) => ({ value, label })) // Убираем count из конечного результата
				}
				//@ts-ignore
				prefix={`${t('mainPage.filters.prefixes.land')}:`}
				placeholder={t('mainPage.filters.placeholders.all')}
				value={dictionaries.lands
					?.filter((land) => formik.values.land?.includes(land.code as string))
					.map((land) => ({ value: land, label: land.name }))}
				className='landSelector'
				classNamePrefix='landSelector'
				onChange={(selectedOptions) => {
					formik.setFieldValue(
						'land',
						selectedOptions.map((option) => option.value.code)
					);
					dispatch(
						setLands(
							selectedOptions.map((option) => option.value.code) as string[]
						)
					);
					/* const queryParams = new URLSearchParams(search);
					queryParams.delete('activeMarkerId');
					navigate({
						search: queryParams.toString(),
					}); */
				}}
			/>
			<Select
				isDisabled={
					formik.values.land?.length === 0 || filteredCities.length === 0
				}
				isMulti
				hideSelectedOptions={false}
				components={{
					ValueContainer: CustomValueContainer,
					Option: CustomMultiValueOption,
				}}
				options={
					filteredCities
						.map((city) => {
							const count = optionsCounter(filteredObjects, 'city', city.name);
							return {
								value: city,
								label: count > 0 ? `${city.name} (${count})` : city.name,
								count, // добавляем count для сортировки
							};
						})
						.sort((a, b) => {
							// Если count отличается, то элементы с count > 0 идут перед count === 0
							if (a.count === 0 && b.count !== 0) return 1;
							if (a.count !== 0 && b.count === 0) return -1;
							// Если count равен, сортируем по label в алфавитном порядке
							return (a.label as string).localeCompare(b.label as string);
						})
						.map(({ value, label }) => ({ value, label })) // Убираем count из конечного результата
				}
				//@ts-ignore
				prefix={`${t('mainPage.filters.prefixes.city')}:`}
				placeholder={
					filteredCities.length === 0
						? t('mainPage.filters.placeholders.noCities')
						: t('mainPage.filters.placeholders.all')
				}
				value={filteredCities
					.filter((city) => formik.values.city?.includes(city.name as string))
					.map((city) => ({ value: city, label: city.name }))}
				className='citySelector'
				classNamePrefix='citySelector'
				onChange={(selectedOptions) => {
					formik.setFieldValue(
						'city',
						selectedOptions.map((option) => option.value.name)
					);
					dispatch(
						setCities(
							selectedOptions.map((option) => option.value.name) as string[]
						)
					);
					/* const queryParams = new URLSearchParams(search);
					queryParams.delete('activeMarkerId');
					navigate({
						search: queryParams.toString(),
					}); */
				}} 
			/>
			{/* <Button className='searchBtn' type='submit'>
				{t('mainPage.mainFilter.searchBtn')}
			</Button> */}
		</form>
	);
};

export default MainFilters;
