import React, {
	createContext,
	useState,
	useEffect,
	PropsWithChildren,
	useContext,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
	Api,
	DomainCity,
	DomainLand,
	DomainTypes,
	TransportUserProfile,
	TransportUserSignInRequest,
} from '../shared/api/Api';

interface IApi {
	api: Api<unknown> | null;
	user: TransportUserProfile | null;
	error: string;
	setError: (error: string) => void;
	signIn: (signInData: TransportUserSignInRequest) => void;
	signOut: () => void;
	getTime: () => Promise<number>;
	getUser: () => void;
	getLands: () => Promise<DomainLand[]>;
	getCities: () => Promise<DomainCity[]>;
	getTypes: () => Promise<DomainTypes[]>;
	downloadFile: (id: number) => Promise<string>;
}

const ApiContext = createContext<IApi>({
	api: null,
	user: null,
	error: '',
	setError: () => null,
	signIn: () => null,
	signOut: () => null,
	getTime: async () => 0,
	getUser: () => null,
	getLands: () => Promise.resolve([]),
	getCities: () => Promise.resolve([]),
	getTypes: () => Promise.resolve([]),
	downloadFile: () => Promise.resolve(''),
});

const ApiProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [isSignedOut, setIsSignedOut] = useState<boolean>(false);

	const [user, setUser] = useState<TransportUserProfile | null>(null);

	const [error, setError] = useState('');

	const api = new Api({
		withCredentials: true,
		baseURL: `/api/v1`,
	});

	const navigate = useNavigate(); // используем хук для навигации
	const { t } = useTranslation();

	const getUser = () =>
		api.a
			.getA()
			.then((response) => setUser(response.data))
			.catch((err) => {
				if (err.response.status === 401) {
					setUser(null);
					setError(err.response.data.err);
					if (!window.location.href.includes('/sign-in')) {
						// Перенаправляем только если мы ещё не находимся на /sign-in
						navigate('/sign-in');
						toast.error(t('toasts.error.unauthorized'));
					}
				} else if (err.response.status === 500) {
					setError('Internal server error');
				}
			});

	const signIn = async (values: TransportUserSignInRequest) => {
		try {
			const response = await api.signin.signinCreate(values);
			setUser(response.data);
			// console.log('SIGNIN USER:', user);
			// localStorage.setItem('user', JSON.stringify(response.data));
			navigate('/');
		} catch (err: any) {
			// В случае ошибки задаем сообщение об ошибке
			if (err.response?.data?.err) {
				setError(err.response.data.err);
			} else {
				setError('An unknown error occurred');
			}
			throw err; // Прокидываем ошибку дальше
		}
	};

	const signOut = () => {
		api.a
			.signoutList()
			.then((_response) => {
				// localStorage.removeItem('user');
				setUser(null);
				setIsSignedOut(true);
			})
			.catch((error) => console.error(error));
	};

	const getTime = () =>
		api.time.timeList().then((response) => {
			// console.log('API TIME:', response.data);
			return response.data;
		});

	const getLands = async () => {
		try {
			const response = await api.dictionary.landsList();
			return response.data;
		} catch (error) {
			console.error('Ошибка при получении списка земель:', error);
			throw error; // Перебрасываем ошибку, чтобы обработать ее в вызывающем коде
		}
	};

	const getCities = async () => {
		try {
			const response = await api.dictionary.citiesList();
			return response.data;
		} catch (error) {
			console.error('Ошибка при получении списка городов:', error);
			throw error; // Перебрасываем ошибку, чтобы обработать ее в вызывающем коде
		}
	};

	const getTypes = async () => {
		try {
			const response = await api.dictionary.typesList();
			return response.data;
		} catch (error) {
			console.error('Ошибка при получении списка земель:', error);
			throw error; // Перебрасываем ошибку, чтобы обработать ее в вызывающем коде
		}
	};

	const downloadFile = async (id: number) => {
		try {
			const response = await api.public.downloadFile(id);
			return response.request.responseURL;
		} catch (error) {
			console.error('Ошибка при получении списка земель:', error);
			throw error; // Перебрасываем ошибку, чтобы обработать ее в вызывающем коде
		}
	};

	useEffect(() => {
		if (!isSignedOut) {
			getUser();
		}
		if (isSignedOut) {
			setIsSignedOut(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ApiContext.Provider
			value={{
				api,
				user,
				error,
				setError,
				signIn,
				signOut,
				getTime,
				getUser,
				getLands,
				getCities,
				getTypes,
				downloadFile,
			}}>
			{children}
		</ApiContext.Provider>
	);
};

export default ApiProvider;
export const useApi = () => useContext(ApiContext);
