import de from './de';
import en from './en';
import ru from './ru';

const resources = {
	ru,
	en,
	de,
};

export default resources;
