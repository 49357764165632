export const timezones = [
	{
		utc: '-1',
		city: 'Azores',
	},
	{
		utc: '+0',
		city: 'London',
	},
	{
		utc: '+1',
		city: 'Paris',
	},
	{
		utc: '+2',
		city: 'Berlin',
	},
	{
		utc: '+3',
		city: 'Moscow',
	},
	{
		utc: '+4',
		city: 'AbuDhabi',
	},
];
