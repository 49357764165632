import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: { page: number; limit: number; total: number } = {
	page: 0,
	limit: 30,
	total: 0,
};

export const pageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		/* nextPage(state) {
			state.page += 1;
		},
		prevPage(state) {
			state.page -= 1;
		}, */
		setPage(state, action: PayloadAction<number>) {
			state.page = action.payload;
		},
		setLimit(state, action: PayloadAction<number>) {
			state.limit = action.payload;
		},
		setTotal(state, action: PayloadAction<number>) {
			state.total = action.payload;
		},
	},
});

export default pageSlice.reducer;
